const hostnamePrefix = /^([a-z]{2})\./;

const match = hostnamePrefix.exec(window.location.hostname);

window.language = match ? match[1] : "en";

const langPrefix = "/" + language;

function document_referrer() {
    return document.referrer;
}

// Simplified gettext, use localized() function.
// Map from "string ids" to localized string.
// If no translation is specified, the "id" is used.
const localizedStrings = {
    Show: {
        ru: "Показать",
        fr: "Afficher",
        de: "Zeigen",
        es: "Mostrar",
        it: "Mostra",
        pt: "Mostrar",
    },
    Hide: {
        ru: "Скрыть",
        fr: "Masquer",
        de: "Verstecken",
        es: "Ocultar",
        it: "Nascondi",
        pt: "Esconder",
    },
    Sent: {
        ru: "Отправлено",
        fr: "Envoyé",
        de: "Gesendet",
        es: "Enviado",
        it: "Inviato",
        pt: "Enviado",
    },
    "Accepted!": {
        ru: "Принято!",
        fr: "Accepté!",
        de: "Akzeptiert!",
        es: "¡Aceptado!",
        it: "Accettato!",
        pt: "Aceite!",
    },
    "Declined!": {
        ru: "Отклонено!",
        fr: "Refusé!",
        de: "Abgelehnt!",
        es: "¡Rechazado!",
        it: "Rifiutato!",
        pt: "Recusado!",
    },
    "Close menu": {
        ru: "Закрыть меню",
        fr: "Fermer le menu",
        de: "Menü schließen",
        es: "Cerrar menú",
        it: "Chiudi menu",
        pt: "Fechar menu",
    },
    "Open menu": {
        ru: "Открыть меню",
        fr: "Ouvrir le menu",
        de: "Menü öffnen",
        es: "Abrir menú",
        it: "Apri menu",
        pt: "Abrir menu",
    },
    "All future events": {
        ru: "Все будущие события",
    },
};

function localized(id) {
    return localizedStrings[id][language] ?? id;
}

if (language == "ru") {
    window.calendarLangParams = {
        closeText: "Закрыть",
        prevText: "&#x3C;Пред",
        nextText: "След&#x3E;",
        currentText: "Сегодня",
        monthNames: [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь",
        ],
        monthNamesShort: [
            "Янв",
            "Фев",
            "Мар",
            "Апр",
            "Май",
            "Июн",
            "Июл",
            "Авг",
            "Сен",
            "Окт",
            "Ноя",
            "Дек",
        ],
        dayNames: [
            "воскресенье",
            "понедельник",
            "вторник",
            "среда",
            "четверг",
            "пятница",
            "суббота",
        ],
        dayNamesShort: ["вск", "пнд", "втр", "срд", "чтв", "птн", "сбт"],
        dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
        weekHeader: "Нед",
    };
} else if (language == "fr") {
    window.calendarLangParams = {
        closeText: "Fermer",
        prevText: "&#x3C;Préc",
        nextText: "Suiv&#x3E;",
        currentText: "Aujourd'hui",
        monthNames: [
            "Janvier",
            "Février",
            "Mars",
            "Avril",
            "Mai",
            "Juin",
            "Juillet",
            "Août",
            "Septembre",
            "Octobre",
            "Novembre",
            "Décembre",
        ],
        monthNamesShort: [
            "Jan",
            "Fév",
            "Mar",
            "Avr",
            "Mai",
            "Jui",
            "Jul",
            "Aoû",
            "Sep",
            "Oct",
            "Nov",
            "Déc",
        ],
        dayNames: [
            "dimanche",
            "lundi",
            "mardi",
            "mercredi",
            "jeudi",
            "vendredi",
            "samedi",
        ],
        dayNamesShort: ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
        dayNamesMin: ["di", "lu", "ma", "me", "je", "ve", "sa"],
        weekHeader: "Sem",
    };
} else if (language == "es") {
    window.calendarLangParams = {
        closeText: "Cerrar",
        prevText: "&#x3C;Prev",
        nextText: "Sigu&#x3E;",
        currentText: "Hoy",
        monthNames: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
        ],
        monthNamesShort: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic",
        ],
        dayNames: [
            "domingo",
            "lunes",
            "martes",
            "miércoles",
            "jueves",
            "viernes",
            "sábado",
        ],
        dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
        dayNamesMin: ["do", "lu", "ma", "mi", "ju", "vi", "sa"],
        weekHeader: "Sem",
    };
} else if (language == "de") {
    window.calendarLangParams = {
        closeText: "Schließen",
        prevText: "&#x3C;Vorh",
        nextText: "Näch&#x3E;",
        currentText: "Heute",
        monthNames: [
            "Januar",
            "Februar",
            "März",
            "April",
            "Mai",
            "Juni",
            "Juli",
            "August",
            "September",
            "Oktober",
            "November",
            "Dezember",
        ],
        monthNamesShort: [
            "Jan",
            "Feb",
            "Mär",
            "Apr",
            "Mai",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Dez",
        ],
        dayNames: [
            "Sonntag",
            "Montag",
            "Dienstag",
            "Mittwoch",
            "Donnerstag",
            "Freitag",
            "Samstag",
        ],
        dayNamesShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
        dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        weekHeader: "Woc",
    };
} else if (language == "it") {
    window.calendarLangParams = {
        closeText: "Chiudere",
        prevText: "&#x3C;Prec",
        nextText: "Segu&#x3E;",
        currentText: "Oggi",
        monthNames: [
            "Gennaio",
            "Febbraio",
            "Marzo",
            "Aprile",
            "Maggio",
            "Giugno",
            "Luglio",
            "Agosto",
            "Settembre",
            "Ottobre",
            "Novembre",
            "Dicembre",
        ],
        monthNamesShort: [
            "Gen",
            "Feb",
            "Mar",
            "Apr",
            "Mag",
            "Giu",
            "Lug",
            "Ago",
            "Set",
            "Ott",
            "Nov",
            "Dic",
        ],
        dayNames: [
            "domenica",
            "lunedì",
            "martedì",
            "mercoledì",
            "giovedì",
            "venerdì",
            "sabato",
        ],
        dayNamesShort: ["dom", "lun", "mar", "mer", "gio", "ven", "sab"],
        dayNamesMin: ["do", "lu", "ma", "me", "gi", "ve", "sa"],
        weekHeader: "Set",
    };
} else if (language == "pt") {
    window.calendarLangParams = {
        closeText: "Fechar",
        prevText: "&#x3C;Ante",
        nextText: "Próx&#x3E;",
        currentText: "Hoje",
        monthNames: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ],
        monthNamesShort: [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
        ],
        dayNames: [
            "domingo",
            "segunda-feira",
            "terça-feira ",
            "quarta-feira",
            "quinta-feira",
            "sexta-feira",
            "sábado",
        ],
        dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
        dayNamesMin: ["do", "se", "te", "qu", "qu", "se", "sá"],
        weekHeader: "Sem",
    };
} else {
    window.calendarLangParams = {
        dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    };
}

$(".burger-menu-btn").click(() => {
    $(".header__menu-mobile").toggle();
});

// regions
$(".region").click(function (el) {
    var id = $(this).data("id");
    $(".region__region-countries").css("display", "none");
    $(".region__region-countries-" + id).css("display", "block");
    $(".region__region-countries-" + id + " .similiar-slider").slick("unslick");
    $(".region__region-countries-" + id + " .similiar-slider").slick(
        "setPosition"
    );
    $(".region__region-countries-" + id + " .similiar-slider").slick({
        TouchMove: true,
        touchThreshold: 300,
        accessibility: false,
        arrows: false,
        dots: false,
        draggable: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        waitForAnimate: true,
        responsive: [
            {
                breakpoint: 1190,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 525,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });
    var d = $(".region__region-countries-" + id).offset().top;
    $("html").animate({ scrollTop: d }, 500);
});

// Все фрагментные ссылки кроме попапов - плавно скроллить
$('a[href^="#"]:not(a.popup-link, a.close)').bind(
    "click.scroll_slow",
    function (e) {
        e.preventDefault();
        if (
            $(this).attr("class") == "click.scroll_slow" ||
            $(this).attr("class") == "scroll_slow"
        ) {
            var target = decodeURIComponent(this.hash),
                $target = $(target);
            $("html, body")
                .stop()
                .animate(
                    { scrollTop: $target.offset().top },
                    700,
                    "swing",
                    function () {
                        window.location.hash = target;
                    }
                );
        }
    }
);

// Если tooltipster слева, подвинуть на 200px вправо
let i = setInterval(function () {
    if (document.querySelector(".tooltipster-base")) {
        if ($(".tooltipster-base").position().left == 0) {
            $(".tooltipster-base").css("position", "absolute");
            $(".tooltipster-base").css("left", "200px");
        }
    }
}, 1000);

// Скрытие контекстного окна Календаря по клику на крестик
$("body").delegate(".main-tooltip-close", "click", function () {
    /*$('.tooltipster-base').hide('slow');*/
    $(".tooltipster-base").css("display", "none");
    $(".tooltipster-base").css("display", "hidden");
    $(".ui-datepicker-calendar").focus();
    $(".ui-datepicker-calendar").click();
});

// Активность кнопок фильтров "только в туре", "по алфавиту", "по рейтингу"
$(".popular-bands__button_ontour").click(function () {
    $(".popular-bands__button_ontour").addClass(
        "popular-bands__button_ontour_active"
    );

    $(".popular-bands__button_alpha").removeClass(
        "popular-bands__button_alpha_active"
    );
    $(".popular-bands__button_rate").removeClass(
        "popular-bands__button_rate_active"
    );
});

$(".popular-bands__button_alpha").click(function () {
    $(".popular-bands__button_alpha").addClass(
        "popular-bands__button_alpha_active"
    );

    $(".popular-bands__button_rate").removeClass(
        "popular-bands__button_rate_active"
    );
    $(".popular-bands__button_ontour").removeClass(
        "popular-bands__button_ontour_active"
    );
});

$(".popular-bands__button_rate").click(function () {
    $(".popular-bands__button_rate").addClass(
        "popular-bands__button_rate_active"
    );

    $(".popular-bands__button_ontour").removeClass(
        "popular-bands__button_ontour_active"
    );
    $(".popular-bands__button_alpha").removeClass(
        "popular-bands__button_alpha_active"
    );
});

// Определяем разрешение экрана. Для мобильных показываем 5 блоков в filtered_events для остальных 10
var device = "";
var items_per_page = 9;

if (window.matchMedia("(min-width: 0px) and (max-width: 320px)").matches)
    device = "mobile";
if (window.matchMedia("(min-width: 0px) and (max-width: 420px)").matches)
    device = "mobile";
if (window.matchMedia("(min-width: 0px) and (max-width: 567px)").matches)
    device = "mobile";
if (window.matchMedia("(min-width: 568px)").matches) device = "desktop";

if (device == "mobile" || device === "tablet") items_per_page = 4;
else items_per_page = 9;

// fix для страницы event, два блока кнопок трекинга
if (device == "desktop") $(".buttons-gonnago__show-mobile").remove();

if (device == "mobile" || device === "tablet")
    $(".buttons-gonnago__hide-mobile").remove();

$(".tickets .calendar-date, .tickets .ticket, .tickets .ticket-page").each(
    function () {
        if ($(this).data("item-id") > items_per_page) {
            $(this).addClass("hidden");
        }
    }
);

var _typeof =
    typeof Symbol === "function" && typeof Symbol.iterator === "symbol"
        ? function (obj) {
              return typeof obj;
          }
        : function (obj) {
              return obj &&
                  typeof Symbol === "function" &&
                  obj.constructor === Symbol &&
                  obj !== Symbol.prototype
                  ? "symbol"
                  : typeof obj;
          };

function queryStringEncode(obj) {
    return (
        "?" +
        Object.keys(obj)
            .map(function (key) {
                return key + "=" + encodeURIComponent(obj[key]);
            })
            .join("&")
    );
}

function queryStringDecode() {
    var res = {};
    window.location.search
        .replace(/^\?/, "")
        .split("&")
        .forEach(function (part) {
            var kv = part.split("=");
            res[kv[0]] = decodeURIComponent(kv[1]);
        });
    return res;
}

$(document).ready(function () {
    $(document).ajaxError(function (event, jqxhr) {
        if (jqxhr.status == 401) {
            // window.scrollTo(0, 0);
            $(".signin__form").addClass("signin__form_show");
            $(".signin__form").css({ "border-spacing": "0px" });
            $(".signin__form").animate(
                { "border-spacing": "1px" },
                {
                    step: function (now, fx) {
                        var x = -1 + 5 * now;
                        var angle = (Math.sin(x * Math.PI) / (x * Math.PI)) * 6;
                        $(this).css("transform", "rotate(" + angle + "deg)");
                    },
                    duration: 300,
                    easing: "linear",
                }
            );
        }
    });

    $("#datepicker-from").datepicker({
        ...window.calendarLangParams,
        dateFormat: "dd.mm.yy",
        minDate: "today",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: "",
    });
    $("#datepicker-to").datepicker({
        ...window.calendarLangParams,
        minDate: "today",
        dateFormat: "dd.mm.yy",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: "",
    });
    $("#datepicker-from__past-gigs").datepicker({
        ...window.calendarLangParams,
        maxDate: "today",
        dateFormat: "dd.mm.yy",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: "",
    });
    $("#datepicker-to__past-gigs").datepicker({
        ...window.calendarLangParams,
        dateFormat: "dd.mm.yy",
        maxDate: "today",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: "",
    });

    function isoDate(date) {
        return (
            date.getFullYear() +
            "-" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + date.getDate()).slice(-2)
        );
    }

    function isoDateFromDatePicker(jqueryObject) {
        const date = jqueryObject.datepicker("getDate");
        return date == null ? null : isoDate(date);
    }

    Date.prototype.addDays = function (days) {
        var dat = new Date(this.valueOf());
        dat.setDate(dat.getDate() + days);
        return dat;
    };
    var EventEmitter = {
        //События
        _events: {},
        dispatch: function dispatch(event, data) {
            if (!this._events[event]) return;
            for (var i = 0; i < this._events[event].length; i++) {
                this._events[event][i](data);
            }
        },
        subscribe: function subscribe(event, callback) {
            if (!this._events[event]) this._events[event] = [];
            this._events[event].push(callback);
        },
    };

    var BandsStorage = {
        _data: {
            type: "",
            page: 1,
            button: false,
            ontour: false,
            genres: "",
            hasNext: true,
            offset: 0,
        },

        update: function update(data) {
            for (var key in data) {
                if (this._data.hasOwnProperty(key)) {
                    this._data[key] = data[key];
                }
            }
            EventEmitter.dispatch("dataUpdated", this._data);
        },

        getData: function getData() {
            return this._data;
        },

        getType: function getType() {
            return this._data.type;
        },

        getPage: function getPage() {
            return this._data.page;
        },

        getOffset: function getOffset() {
            return this._data.offset;
        },

        addOffset: function addOffset() {
            this._data.offset += 1;
        },

        removeOffset: function removeOffset() {
            if (this._data.offset > 0) this._data.offset -= 1;
            else this._data.offset = 0;
        },
    };

    let startDate =
        $("#datepicker-from").length > 0
            ? isoDateFromDatePicker($("#datepicker-from"))
            : $("#datepicker-from__past-gigs").length > 0
            ? isoDateFromDatePicker($("#datepicker-from__past-gigs"))
            : null;
    let endDate =
        $("#datepicker-to").length > 0
            ? isoDateFromDatePicker($("#datepicker-to"))
            : $("#datepicker-to__past-gigs").length > 0
            ? isoDateFromDatePicker($("#datepicker-to__past-gigs"))
            : null;

    const order = $("#datepicker-from__past-gigs").length > 0 ? "reverse" : "straight";

    if (order == "straight") {
        startDate = startDate ?? isoDate(new Date());
        endDate = endDate ?? "upcm";
    } else {
        startDate = startDate ?? "past";
        endDate = endDate ?? isoDate(new Date());
    }

    var Storage = {
        // Parameter storage for filtering events
        // TODO: dismantle altogether

        _data: {
            type: "update", // update = get next page, 'get' = get from start
            endDate,
            startDate,
            page: 1,
            order,
            gonnago: false,
            onlymybands: false,
            hidecancelled: false,
            genre: null,
        },

        update: function update(data) {
            for (var key in data) {
                if (this._data.hasOwnProperty(key)) {
                    this._data[key] = data[key];
                }
            }
            EventEmitter.dispatch("dataUpdated", this._data);
        },

        getData: function getData() {
            return this._data;
        },

        getType: function getType() {
            return this._data.type;
        },

        getPage: function getPage() {
            return this._data.page;
        },
    };

    // Нижний блок билетов должен учитывать, на какой мы странице вообще находимся
    if (window.filteredTicketParams) {
        Storage.update(window.filteredTicketParams);
    }

    var csrftoken = getCookie("csrftoken");

    function csrfSafeMethod(method) {
        // these HTTP methods do not require CSRF protection
        return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
    }

    $.ajaxSetup({
        crossDomain: false, // obviates need for sameOrigin test
        beforeSend: function beforeSend(xhr, settings) {
            if (!csrfSafeMethod(settings.type)) {
                xhr.setRequestHeader("X-CSRFToken", csrftoken);
            }
        },
    });

    function load_page(
        page_url,
        paginationfield_id,
        loadbutton_id,
        pagediv_id
    ) {
        page = parseInt($(paginationfield_id).val());

        $(loadbutton_id).prop("disabled", true);
        $(loadbutton_id).text("Loading ...");

        $.ajax({
            async: true,
            type: "GET",
            url: page_url,
            data: {
                page: page,
                genres_or_bands: JSON.stringify(
                    $("#sgbg-select")
                        .select2("data")
                        .map(function (item) {
                            return {
                                id: item.id,
                            };
                        })
                ),
            },
            error: function error() {
                $(loadbutton_id).replaceWith("<p></p>");
            },
            success: function success(data) {
                $.ajax({
                    async: true,
                    type: "HEAD",
                    url: page_url,
                    data: {
                        page: page + 1,
                        genres_or_bands: JSON.stringify(
                            $("#sgbg-select")
                                .select2("data")
                                .map(function (item) {
                                    return {
                                        id: item.id,
                                    };
                                })
                        ),
                    },
                    error: function error(data) {
                        $(loadbutton_id).hide();
                        $(loadbutton_id).prop("disabled", false);
                        $(paginationfield_id).val(2);
                        $(loadbutton_id).text("Показать еще");
                    },
                    success: function success(response) {
                        $(loadbutton_id).show();
                        $(loadbutton_id).text("Показать еще");
                        $(paginationfield_id).val(page + 1);
                        $(loadbutton_id).prop("disabled", false);
                    },
                });
                $(pagediv_id).append(data);
            },
        });
    }

    function validateEmail(email) {
        var re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    $("#settings-pick-date").datepicker();

    $(".slider-for").slick({
        TouchMove: true,
        touchThreshold: 300,
        accessibility: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: ".slider-nav",
    });

    $(".slider-nav").slick({
        TouchMove: true,
        touchThreshold: 300,
        accessibility: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: ".slider-for",
        dots: true,
        centerMode: true,
        focusOnSelect: true,
    });

    $("#mb-select").select2({
        minimumInputLength: 3,
        ajax: {
            url: "/profile/search_group_by_genre/",
            method: "POST",
            data: function data(params) {
                var query = {
                    search: params.term,
                };
                return query;
            },
        },
    });

    ///////////////////////////////////
    //Поисковые формы                //
    ///////////////////////////////////

    //Hide search box via esc
    $(document).keyup(function (e) {
        if (e.which === 27) {
            $(".header .header-global-search__block").hide();
            $(".header__menu-mobile").hide();
        }
    });

    //Hide one of .header-global-search__block
    $(".header .header__input").keyup(function (e) {
        $(".header .header__input").trigger("change");

        $(".header .header-global-search__block").show();
        $(".header .header-global-search__block").css({ display: "block" });
        $(".global-search__link").focus();
        $(".header .header__input").focus();
    });

    //Show clear button
    $(".header .header__input").keyup(function () {
        if ($(".header .header__input").val() !== "") {
            $(".header .input__clear").addClass(
                "input__clear_visibility_visible"
            );
        } else {
            $(".header .input__clear").removeClass(
                "input__clear_visibility_visible"
            );
        }
    });

    //Clear input by Clear button
    $(".header .input__clear").click(function () {
        if ($(".header .header__input").val !== " ") {
            $(".header .header__input").val("");
            $(".header .input__clear").removeClass(
                "input__clear_visibility_visible"
            );
        }
    });

    //Hide .header-global-search__block
    $(document).mouseup(function (e) {
        var div = $(".header-global-search__block");
        if (!div.is(e.target) && div.has(e.target).length === 0) {
            div.hide();
        }
    });

    //Minimize header
    $(window).scroll(function () {
        if ($(this).scrollTop() > 80) {
            $(".header").addClass("header-min");
            $(".header__content").addClass("header__content-min");
            $(".logged").addClass("logged-min");
            $(".user").addClass("user-min");
        } else {
            $(".header").removeClass("header-min");
            $(".header__content").removeClass("header__content-min");
            $(".logged").removeClass("logged-min");
            $(".user").removeClass("user-min");
        }
    });

    //Check to see if the window is top if not then display button
    $(window).scroll(function () {
        if ($(this).scrollTop() > 200) {
            $(".to-top").fadeIn();
        } else {
            $(".to-top").fadeOut();
        }
    });

    //Click event to scroll to top
    $(".to-top").click(function () {
        $("html, body").animate({ scrollTop: 0 }, 800);
        return false;
    });

    ///////////////////////////////////
    //блок по работе с левым профилем//
    ///////////////////////////////////

    $("body").delegate(".my-band__button_remove", "click", function () {
        var element = $(this);
        $.ajax({
            url: "/profile/mybands/delete/",
            method: "POST",
            data: {
                band: $(this).attr("data-id"),
                type: $(this).attr("data-type"),
            },
            success: function success(data) {
                element.removeClass("my-band__button_remove");
                element.addClass("add_btn");
                element.attr("data-type", "del");
            },
        });
    });

    $("body").delegate("#pou-button", "click", function () {
        switch ($("#pou-button").attr("data-type")) {
            case "delete":
                $.ajax({
                    url: "/profile/myfriends/delete_friend/",
                    method: "POST",
                    data: {
                        id: $("#pou-button").attr("data-id"),
                    },
                    success: function success(data) {
                        $("#pou-button").attr("data-type", "add");
                        $("#pou-button").text(data.answer);
                    },
                });
                break;

            case "add":
                $.ajax({
                    url: "/profile/myfriends/send_friend_invite/",
                    method: "POST",
                    data: {
                        id: $("#pou-button").attr("data-id"),
                    },
                    success: function success(data) {
                        $("#pou-button").attr("data-type", "sended");
                        $("#pou-button").attr("disabled", true);
                        $("#pou-button").text(data.answer);
                    },
                });
                break;
            case "receved":
                $.ajax({
                    url: "/profile/myfriends/accept_friend_request/",
                    method: "POST",
                    data: {
                        id: $("#pou-button").attr("data-id"),
                    },
                    success: function success(data) {
                        $("#pou-button").attr("data-type", "delete");
                        $("#pou-button").val(data.answer);
                    },
                });
                break;
        }
    });

    $("body").delegate("#pou-upc-ev", "click", function () {
        $(".timetable__past").removeClass("timetable__upcoming-active");
        $(".timetable__upcoming").addClass("timetable__upcoming-active");
        Storage.update({
            startDate: isoDate(new Date()),
            endDate: "upcm",
            page: 1,
            order: "straight",
            type: "get",
            offset: 0,
        });
        GetTickets(Storage.getData());
        return false;
    });

    $("body").delegate("#pou-pst-ev", "click", function () {
        $(".timetable__upcoming").removeClass("timetable__upcoming-active");
        $(".timetable__past").addClass("timetable__upcoming-active");
        Storage.update({
            startDate: "past",
            endDate: isoDate(new Date()),
            page: 1,
            order: "reversed",
            type: "get",
            offset: 0,
        });
        GetTickets(Storage.getData());
        return false;
    });

    $("body").delegate("#pou-frm-dtpcr", "click", function () {
        if ($("#pou-to-dtpcr").val()) {
            if ($("#pou-to-dtpcr").val() > $(this).val()) {
                Storage.update({
                    type: "get",
                    startDate: isoDateFromDatePicker($(this)),
                    endDate: isoDateFromDatePicker($("#pou-to-dtpcr")),
                    page: 1,
                    order: "reversed",
                    offset: 0,
                });
            } else {
                Storage.update({
                    type: "get",
                    startDate: isoDateFromDatePicker($("#pou-to-dtpcr")),
                    endDate: isoDateFromDatePicker($(this)),
                    page: 1,
                    order: "straight",
                    offset: 0,
                });
            }
            GetTickets(Storage.getData());
            return false;
        }
    });

    $("body").delegate("#pou-to-dtpcr", "click", function () {
        if ($("#pou-frm-dtpcr").val()) {
            if ($("#pou-frm-dtpcr").val() < $(this).val()) {
                Storage.update({
                    start_date: $("#pou-frm-dtpcr").val(),
                    end_date: $(this).val(),
                    page: 1,
                    order: "reversed",
                    type: "get",
                    offset: 0,
                });
            } else {
                Storage.update({
                    start_date: $(this).val(),
                    end_date: $("#pou-frm-dtpcr").val(),
                    page: 1,
                    order: "straight",
                    type: "get",
                    offset: 0,
                });
            }
            GetTickets(Storage.getData());
        }
    });

    $("body").delegate("#pou-omb", "click", function () {
        var mb;
        if (Storage.getData().onlymybands) {
            mb = false;
        } else {
            mb = true;
        }
        Storage.update({
            startDate: isoDate(new Date()),
            endDate: "upcm",
            page: 1,
            order: "straight",
            type: "get",
            onlymybands: mb,
            offset: 0,
        });
        GetTickets(Storage.getData());
        return false;
    });

    ///////////////////////////////
    //Блок по работе с событиями//
    //////////////////////////////

    var filteredTicketsPage = 0;
    filteredTicketsPage = filteredTicketsPage + items_per_page;

    function moreTicketsToShow() {
        //return $('.ticket-page-' +(filteredTicketsPage+1)).length;
        return $(".ticket-page-" + filteredTicketsPage).length;
    }

    function GetTickets(data, bandHasSuggestionEvents) {
        $(".timetable__spinner").addClass("visible");
        $.ajax({
            url: "filtered/",
            method: "POST",
            data: {
                start: data.startDate,
                end: data.endDate,
                order: data.order,
                loadRemaining: data.page > 1,
                gonnago: data.gonnago,
                onlymybands: data.onlymybands,
                hidecancelled: data.hidecancelled,
                genre: data.genre,
                splitFests:
                    !window.location.href.includes("/location/") &&
                    !window.location.href.includes("/fests/"),
                bandHasSuggestionEvents,
            },
            success: function success(data) {
                filteredTicketsPage = 0;
                if (Storage.getPage() == 1) {
                    DisplayEventBars(data);
                } else if (Storage.getPage() > 1) {
                    DisplayAdditionalEventBars(data);
                }
                hideShortLineups();
                if (typeof collectAdsOnThePage !== "undefined") {
                    collectAdsOnThePage(); // Adnimation - add banners within the new event bars
                }
                window.tickedAutoloadTriggered = false;
                BandsStorage.update({ hasNext: data.more_events_to_load }); //есть ли необходимость хранить это в сторадже? Наверное пока нет
            },
            complete: function complete(data) {
                $(".timetable__spinner").removeClass("visible");
            },
        });
    }

    function GetMyBands(data) {
        $.ajax({
            url: "filtered/",
            method: "POST",
            data: {
                genres: JSON.stringify(data.genres),
                page: data.page,
                ontour: data.ontour,
            },
            success: function success(data) {
                if (BandsStorage.getType() == "get") {
                    EventEmitter.dispatch("MyBandsGot", data);
                } else if (BandsStorage.getType() == "update") {
                    EventEmitter.dispatch("UpdatedMyBandsGot", data);
                } else {
                }
                BandsStorage.update({ hasNext: data.more_events_to_load }); //есть ли необходимость хранить это в сторадже? Наверное пока нет
            },
        });
    }

    EventEmitter.subscribe("NeedMyBands", GetMyBands);

    //////////////////////////////////
    //Блок по отрисовке компонентов//
    ////////////////////////////////

    function DisplayEventBars(data) {
        $("#event_bars").html(data.html);

        // Определяем разрешение экрана. Для мобильных показываем 5 блоков в filtered_events для остальных 10
        var device = "";
        var items_per_page = 9;

        if (
            window.matchMedia("(min-width: 0px) and (max-width: 320px)").matches
        )
            device = "mobile";
        if (
            window.matchMedia("(min-width: 0px) and (max-width: 420px)").matches
        )
            device = "mobile";
        if (
            window.matchMedia("(min-width: 0px) and (max-width: 567px)").matches
        )
            device = "mobile";
        if (window.matchMedia("(min-width: 568px)").matches) device = "desktop";

        if (device == "mobile") items_per_page = 4;
        else items_per_page = 9;

        $(
            ".tickets .calendar-date, .tickets .ticket, .tickets .ticket-page"
        ).each(function () {
            if ($(this).data("item-id") > items_per_page) {
                $(this).addClass("hidden");
                //$(this).prev('h2').addClass('hidden');
            }
        });

        if (data.more_events_to_load) {
            $("#show-more-tickets").show();
        } else {
            $("#show-more-tickets").hide();
        }
    }

    function DisplayAdditionalEventBars(data) {
        $("#event_bars").append(data.html);
        if (data.more_events_to_load) {
            $("#show-more-tickets").show();
        } else {
            $("#show-more-tickets").hide();
        }
    }

    function DrawMyBands(data) {
        $("#mb-block").html(data.html);
        if (data.more_events_to_load) {
            $("#show-more-my-bands").show();
        } else {
            $("#show-more-my-bands").hide();
        }
    }

    function DrawUpdatedMyBands(data) {
        $(data.html).insertAfter($("#mb-block").find(".ticket").last());
        if (data.more_events_to_load) {
            $("#show-more-my-bands").show();
        } else {
            $("#show-more-my-bands").hide();
        }
    }

    EventEmitter.subscribe("MyBandsGot", DrawMyBands);
    EventEmitter.subscribe("UpdatedMyBandsGot", DrawUpdatedMyBands);

    ////////////////////////////////////////////////////////
    //Обработчики кликов по элементам связанные с билетами//
    ///////////////////////////////////////////////////////

    $("#datepicker-from__past-gigs").change(function () {
        if ($("#datepicker-to__past-gigs").val()) {
            if (
                $("#datepicker-to__past-gigs").datepicker("getDate") >
                $(this).datepicker("getDate")
            ) {
                Storage.update({
                    startDate: isoDateFromDatePicker($(this)),
                    endDate: isoDateFromDatePicker(
                        $("#datepicker-to__past-gigs")
                    ),
                    page: 1,
                    order: "straight",
                    offset: 0,
                });
            } else {
                Storage.update({
                    startDate: isoDateFromDatePicker(
                        $("#datepicker-to__past-gigs")
                    ),
                    endDate: isoDateFromDatePicker($(this)),
                    page: 1,
                    order: "reversed",
                    offset: 0,
                });
            }
            GetTickets(Storage.getData());
        } else {
            Storage.update({
                startDate: isoDateFromDatePicker($(this)),
                endDate: "2050-01-01",
                page: 1,
                order: "straight",
                offset: 0,
            });
            GetTickets(Storage.getData());
        }
    });

    $("#datepicker-to__past-gigs").change(function () {
        if ($("#datepicker-from__past-gigs").val()) {
            if (
                $("#datepicker-from__past-gigs").datepicker("getDate") <
                $(this).datepicker("getDate")
            ) {
                Storage.update({
                    startDate: isoDateFromDatePicker(
                        $("#datepicker-from__past-gigs")
                    ),
                    endDate: isoDateFromDatePicker($(this)),
                    page: 1,
                    order: "straight",
                    offset: 0,
                });
            } else {
                Storage.update({
                    startDate: isoDateFromDatePicker($(this)),
                    endDate: isoDateFromDatePicker(
                        $("#datepicker-from__past-gigs")
                    ),
                    page: 1,
                    order: "reversed",
                    offset: 0,
                });
            }
            GetTickets(Storage.getData());
        }
    });

    $("#datepicker-from").change(function () {
        // автоматически подтягивать значение из поля from в поле to если to пустое
        if ($("#datepicker-to").val() == "") {
            $("#datepicker-to").val($("#datepicker-from").val());
        }

        if ($("#datepicker-to").val()) {
            if (
                $("#datepicker-to").datepicker("getDate") >
                $(this).datepicker("getDate")
            ) {
                Storage.update({
                    startDate: isoDateFromDatePicker($(this)),
                    endDate: isoDateFromDatePicker($("#datepicker-to")),
                    page: 1,
                    order: "straight",
                    offset: 0,
                });
            } else {
                Storage.update({
                    startDate: isoDateFromDatePicker($("#datepicker-to")),
                    endDate: isoDateFromDatePicker($(this)),
                    page: 1,
                    order: "reversed",
                    offset: 0,
                });
            }
            GetTickets(Storage.getData());
        } else {
            Storage.update({
                startDate: isoDateFromDatePicker($(this)),
                endDate: "2050-01-01",
                page: 1,
                order: "straight",
                offset: 0,
            });
            GetTickets(Storage.getData());
        }
    });

    $("#datepicker-to").change(function () {
        if ($("#datepicker-from").val()) {
            if (
                $("#datepicker-from").datepicker("getDate") <
                $(this).datepicker("getDate")
            ) {
                Storage.update({
                    startDate: isoDateFromDatePicker($("#datepicker-from")),
                    endDate: isoDateFromDatePicker($(this)),
                    page: 1,
                    order: "straight",
                    offset: 0,
                });
            } else {
                Storage.update({
                    startDate: isoDateFromDatePicker($(this)),
                    endDate: isoDateFromDatePicker($("#datepicker-from")),
                    page: 1,
                    order: "reversed",
                    offset: 0,
                });
            }
            GetTickets(Storage.getData());
        }
    });

    /*
    $('body').delegate('#upcom-ev', 'click', function () {
        $(".timetable__past").removeClass('timetable__upcoming-active');
        $(".timetable__upcoming").addClass('timetable__upcoming-active');
        Storage.update({
            startDate: getDate(new Date()),
            endDate: "upcm",
            page: 1,
            order: 'straight',
            offset: 0
        });
        GetTickets(Storage.getData());
        return false;

    });
    */

    /*
    $('body').delegate('#past-ev', 'click', function () {
        $(".timetable__upcoming").removeClass('timetable__upcoming-active');
        $(".timetable__past").addClass('timetable__upcoming-active');
        Storage.update({
            startDate: "past",
            endDate: getDate(new Date()),
            page: 1,
            order: 'reversed',
            offset: 0
        });
        GetTickets(Storage.getData());
        return false;
    });
    */

    $(".timetable__button_koza").hover(
        function () {
            //$(this).find('span').show();
        },
        function () {
            //$(this).find('span').hide();
        }
    );

    $("body").delegate(".timetable__button_koza", "click", function () {
        var gg;

        if (Storage.getData().gonnago) {
            gg = false;
        } else {
            gg = true;
        }

        Storage.update({
            gonnago: gg,
            startDate: isoDate(new Date()),
            endDate: "upcm",
            page: 1,
            order: "straight",
            offset: 0,
        });

        GetTickets(Storage.getData());

        return false;
    });

    $(".timetable__button_bands").hover(
        function () {
            //$(this).find('span').show();
        },
        function () {
            //$(this).find('span').hide();
        }
    );

    $("body").delegate(".timetable__button_bands", "click", function () {
        var mb;

        if (Storage.getData().onlymybands) {
            mb = false;
        } else {
            mb = true;
        }

        Storage.update({
            startDate: isoDate(new Date()),
            endDate: "upcm",
            page: 1,
            order: "straight",
            onlymybands: mb,
            offset: 0,
        });

        GetTickets(Storage.getData());
        return false;
    });

    $("body").delegate(
        '.timetable__button_cancelled, .timetable__button_cancelled input[type="checkbox"]',
        "click",
        function () {
            var cancelled;

            if (Storage.getData().hidecancelled) {
                cancelled = false;
            } else {
                cancelled = true;
            }

            Storage.update({
                startDate: isoDate(new Date()),
                endDate: "upcm",
                page: 1,
                order: "straight",
                hidecancelled: cancelled,
                offset: 0,
            });

            GetTickets(Storage.getData());

            if ($('input[type="checkbox"].hidecancelled').is(":checked")) {
                $('input[type="checkbox"].hidecancelled').prop(
                    "checked",
                    false
                );
            } else {
                $('input[type="checkbox"].hidecancelled').prop("checked", true);
            }

            return false;
        }
    );

    $("#show-more-tickets").click(function () {
        $("#show-more-tickets").hide();
        $(".timetable__spinner__outer").css("margin-bottom", "20px");
        // TODO: spinner
        Storage._data.page += 1;
        GetTickets(Storage.getData(), $(this).attr("data-is-suggesion"));
        return false;
    });

    //////////////////////////////////////////////////
    //Обработчики событий связанных с моими группами//
    //////////////////////////////////////////////////

    $("body").delegate("#show-more-my-bands", "click", function () {
        BandsStorage.update({
            type: "update",
            page: BandsStorage.getPage() + 1,
            offset: BandsStorage.getOffset(),
        });
        EventEmitter.dispatch("NeedMyBands", BandsStorage.getData());
        return false;
    });

    $("body").delegate("#mb-ontour", "click", function () {
        BandsStorage.update({
            type: "get",
            page: 1,
            ontour: true,
            offset: 0,
        });
        EventEmitter.dispatch("NeedMyBands", BandsStorage.getData());
        return false;
    });

    $("body").delegate("#mb-all", "click", function () {
        BandsStorage.update({
            type: "get",
            page: 1,
            ontour: false,
            offset: 0,
        });
        EventEmitter.dispatch("NeedMyBands", BandsStorage.getData());
        return false;
    });

    $("#mb-select").on("change.select2", function (e) {
        $.ajax({
            url: "filtered/",
            method: "POST",
            data: {
                genres: JSON.stringify(
                    $("#mb-select")
                        .select2("data")
                        .map(function (item) {
                            return {
                                id: item.id,
                            };
                        })
                ),
                page: 1,
                ontour: BandsStorage.getData().ontour,
            },
            success: function success(data) {
                if ($("#mb-select").select2("data").length > 0) {
                    BandsStorage.update({
                        genres: $("#mb-select")
                            .select2("data")
                            .map(function (item) {
                                return { id: item.id };
                            }),
                    });
                } else {
                    BandsStorage.update({ genres: "" });
                }
                EventEmitter.dispatch("MyBandsGot", data);
            },
        });
    });

    $("body").delegate(".add_btn", "click", function () {
        var element = $(this);
        $.ajax({
            url: "/profile/mybands/delete/",
            method: "POST",
            data: {
                band: $(this).attr("data-id"),
                type: $(this).attr("data-type"),
            },
            success: function success(data) {
                element
                    .parent()
                    .parent()
                    .parent()
                    .find(".ticket__hide")
                    .addClass("show");
                $("#pc-bands").text(Number($("#pc-bands").text()) - 1);
                BandsStorage.addOffset();
            },
        });
    });

    $("body").delegate(".ticket__hide-button", "click", function () {
        var element = $(this);
        $.ajax({
            url: "/profile/mybands/delete/",
            method: "POST",
            data: {
                band: $(this).attr("data-id"),
                type: $(this).attr("data-type"),
            },
            success: function success(data) {
                element.parent().removeClass("show");
                $("#pc-bands").text(Number($("#pc-bands").text()) + 1);
                BandsStorage.removeOffset();
            },
        });
    });

    //////////////////////////////////////////
    //Обработчики связанные с моими городами//
    //////////////////////////////////////////
    var page = 1;
    $(".search_city").keyup(function () {
        page = 1;
        $.ajax({
            url: "/profile/mycities/getcities/",
            method: "POST",
            data: {
                term: $(this).val(),
            },
            success: function success(data) {
                $("#more-cities").html(data.html);
                if (data.more_events_to_load) {
                    $("#show-more-mycities").show();
                } else {
                    $("#show-more-mycities").hide();
                }
            },
        });
    });

    $("body").delegate("#show-more-mycities", "click", function () {
        $.ajax({
            url: "/profile/mycities/getcities/",
            method: "POST",
            data: {
                term: $(".search_city").val(),
                page: page + 1,
            },
            success: function success(data) {
                $(data.html).insertAfter($('[id^="city"]').last());
                if (data.more_events_to_load) {
                    $("#show-more-mycities").show();
                } else {
                    $("#show-more-mycities").hide();
                }
                page = page + 1;
            },
        });
        return false;
    });

    /////////////
    //Настройки//
    /////////////

    //Сохранение по кнопке Save

    $("body").delegate("#form-control-btn", "click", function () {
        //Сохранение FirstName
        $.ajax({
            url: "/profile/mysettings/set_first_name/",
            method: "POST",
            data: {
                first_name: $("#p-mset-first_name").val(),
            },
            success: function success(data) {
                $("#p-mset-first_name").css("border-color", "green");
                $("#p-mset-first_name_acc").html(
                    '<i class="fa fa-check" aria-hidden="true"></i>'
                );
                $("#p-mset-first_name_acc").css("color", "green");
            },
            error: function error(data) {
                $("#p-mset-first_name").css("border-color", "red");
                $("#p-mset-first_name_acc").html(
                    '<i class="fa fa-times-circle" aria-hidden="true"></i>'
                );
                $("#p-mset-first_name_acc").css("color", "red");
            },
        });

        //Сохранение NickName
        $.ajax({
            url: "/profile/mysettings/set_nick_name/",
            method: "POST",
            data: {
                nick_name: $("#p-mset-nick_name").val(),
            },
            success: function success(data) {
                $("#p-mset-nick_name").css("border-color", "green");
                $("#p-mset-nick_name_acc").html(
                    '<i class="fa fa-check" aria-hidden="true"></i>'
                );
                $("#p-mset-nick_name_acc").css("color", "green");
            },
            error: function error(data) {
                $("#p-mset-nick_name").css("border-color", "red");
                $("#p-mset-nick_name_acc").html(
                    '<i class="fa fa-times-circle" aria-hidden="true"></i>'
                );
                $("#p-mset-nick_name_acc").css("color", "red");
            },
        });

        //Сохранение Avatar
        /*$.ajax({
            url: '/profile/mysettings/set_avatar/',
            type: 'POST',
            data: { src: element.parent().find('input[name=settings-avatar]').val() },
            success: function success(data) {
                if (!element.parent().find('.settings__chekbox').hasClass('disabled')) {
                    $('.settings__chekbox').removeClass('active');
                    element.parent().find('.settings__chekbox').addClass('active');
                }
            }
        });*/

        //Сохранение Sex

        //Сохранение Date of birth
        $.ajax({
            url: "/profile/mysettings/change_born_date/",
            method: "POST",
            data: {
                born_date: $("#dt-set-picker").val(),
            },
            success: function success(data) {
                $("#dt-set-picker").css("border-color", "green");
                $("#p-mset-borndate").html(
                    '<i class="fa fa-check" aria-hidden="true"></i>'
                );
                $("#p-mset-borndate").css("color", "green");
            },
            error: function error(data) {
                $("#dt-set-picker").css("border-color", "red");
                $("#p-mset-borndate").html(
                    '<i class="fa fa-times-circle" aria-hidden="true"></i>'
                );
                $("#p-mset-borndate").css("color", "red");
            },
        });

        //Сохранение Date format
    });

    $("body").delegate("#p-mset-first_name_acc", "click", function () {
        $.ajax({
            url: "/profile/mysettings/set_first_name/",
            method: "POST",
            data: {
                first_name: $("#p-mset-first_name").val(),
            },
            success: function success(data) {
                $("#p-mset-first_name").css("border-color", "green");
                $("#p-mset-first_name_acc").html(
                    '<i class="fa fa-check" aria-hidden="true"></i>'
                );
                $("#p-mset-first_name_acc").css("color", "green");
            },
            error: function error(data) {
                $("#p-mset-first_name").css("border-color", "red");
                $("#p-mset-first_name_acc").html(
                    '<i class="fa fa-times-circle" aria-hidden="true"></i>'
                );
                $("#p-mset-first_name_acc").css("color", "red");
            },
        });
    });

    $("body").delegate("#p-mset-nick_name_acc", "click", function () {
        $.ajax({
            url: "/profile/mysettings/set_nick_name/",
            method: "POST",
            data: {
                nick_name: $("#p-mset-nick_name").val(),
            },
            success: function success(data) {
                $("#p-mset-nick_name").css("border-color", "green");
                $("#p-mset-nick_name_acc").html(
                    '<i class="fa fa-check" aria-hidden="true"></i>'
                );
                $("#p-mset-nick_name_acc").css("color", "green");
            },
            error: function error(data) {
                $("#p-mset-nick_name").css("border-color", "red");
                $("#p-mset-nick_name_acc").html(
                    '<i class="fa fa-times-circle" aria-hidden="true"></i>'
                );
                $("#p-mset-nick_name_acc").css("color", "red");
            },
        });
    });

    $("body").delegate(
        ".settings__avatar_container #id_avatar",
        "change",
        function () {
            $(".settings__btn_add").addClass("spinner");
            var file_data = $("#id_avatar").prop("files")[0];
            var form_data = new FormData();
            form_data.append("avatar", file_data);

            $.ajax({
                url: "/profile/mysettings/upload_avatar/",
                type: "POST",
                data: form_data,
                cache: false,
                processData: false,
                contentType: false,
                success: function success(data) {
                    $(".settings__btn_add").removeClass("spinner");
                    $("#site_avatar").attr("src", data.url);
                    $(".profile__image").attr("src", data.url);
                },
            });
            return false;
        }
    );

    $("body").delegate(".settings__avatar", "click", function () {
        var element = $(this);

        if (
            element
                .parent()
                .find("input[name=settings-avatar]")
                .attr("disabled")
        ) {
            return false;
        }

        $.ajax({
            url: "/profile/mysettings/set_avatar/",
            type: "POST",
            data: {
                src: element.parent().find("input[name=settings-avatar]").val(),
            },
            success: function success(data) {
                if (
                    !element
                        .parent()
                        .find(".settings__chekbox")
                        .hasClass("disabled")
                ) {
                    $(".settings__chekbox").removeClass("active");
                    element
                        .parent()
                        .find(".settings__chekbox")
                        .addClass("active");
                    $(".profile__image").attr("src", data.url);
                }
            },
        });

        return false;
    });

    $("body").delegate(".settings__btn_del", "click", function () {
        $.ajax({
            url: "/profile/mysettings/del_avatar/",
            type: "POST",
            success: function success(data) {
                $("#site_avatar").attr("src", data.url);
            },
        });
        return false;
    });

    $("body").delegate("#pass_conf", "click", function () {
        if (!$("#id_password1").val() || !$("#id_password2").val()) {
            $("#id_password1").css("border-color", "red");
            $("#id_password2").css("border-color", "red");
        }
        if ($("#id_password1").val() != $("#id_password2").val()) {
            $("#id_password2").css("border-color", "red");
        }
        $.ajax({
            url: "/profile/mysettings/change_pass/",
            type: "POST",
            data: {
                raw_password: $("#id_raw_password").val(),
                password1: $("#id_password1").val(),
                password2: $("#id_password2").val(),
            },
            success: function success(data) {
                window.location.hash = "#sys-set";
            },
            error: function error(date) {
                $("#id_password1").css("border-color", "red");
                $("#id_password2").css("border-color", "red");
                $("#popup1 input").css("border-color", "red");
            },
        });
    });

    $("body").delegate("#e_pass_conf", "click", function () {
        if (!validateEmail($("#id_email").val())) {
            $("#id_email").css("border-color", "red");
            return false;
        }
        $.ajax({
            url: "/profile/mysettings/change_email/",
            type: "POST",
            data: {
                password: $("#id_password").val(),
                email: $("#id_email").val(),
            },
            success: function success(data) {
                $("#id_email").css("border-color", "green");
                window.location.hash = "#sys-set";
            },
            error: function error(date) {
                $("#id_email").css("border-color", "red");
                $("#popup2 input").css("border-color", "red");
            },
        });
    });

    $("body").delegate(".settings__select_button", "click", function () {
        var element = $(this);
        element.parent().find(".settings__select_button").removeClass("active");
        element.addClass("active");
        return false;
    });

    $("body").delegate(".settings-date-pick", "click", function () {
        var element = $(this);
        $.ajax({
            url: "/profile/mysettings/change_date/",
            type: "POST",
            data: { date: element.val() },
            success: updateDateFormat,
        });
    });

    var datef = $(".settings-date-pick").filter(".active").val();
    window.dtFormat = datef == "1" ? "dd.mm.yy" : "mm/dd/yy";

    function updateDateFormat() {
        var datef = $(".settings-date-pick").filter(".active").val();
        window.dtFormat = datef == "1" ? "dd.mm.yy" : "mm/dd/yy";
        var date = $("#dt-set-picker").datepicker("getDate");
        $("#dt-set-picker").datepicker("option", "dateFormat", window.dtFormat);
        $("#dt-set-picker").datepicker("setDate", date);
    }

    $("#dt-set-picker").datepicker({
        dateFormat: window.dtFormat,
        changeMonth: true,
        changeYear: true,
        yearRange: "1940:+0",
    });

    $("body").delegate(".settings-gender-pick", "click", function () {
        var element = $(this);
        $.ajax({
            url: "/profile/mysettings/change_gender/",
            type: "POST",
            data: { gender: element.val() },
        });
    });

    $("body").delegate("#p-mset-borndate", "click", function () {
        $.ajax({
            url: "/profile/mysettings/change_born_date/",
            method: "POST",
            data: {
                born_date: $("#dt-set-picker").val(),
            },
            success: function success(data) {
                $("#dt-set-picker").css("border-color", "green");
                $("#p-mset-borndate").html(
                    '<i class="fa fa-check" aria-hidden="true"></i>'
                );
                $("#p-mset-borndate").css("color", "green");
            },
            error: function error(data) {
                $("#dt-set-picker").css("border-color", "red");
                $("#p-mset-borndate").html(
                    '<i class="fa fa-times-circle" aria-hidden="true"></i>'
                );
                $("#p-mset-borndate").css("color", "red");
            },
        });
    });

    $("body").delegate("#u_pass_conf", "click", function () {
        $.ajax({
            url: "/profile/mysettings/change_username/",
            type: "POST",
            data: {
                u_password: $("#id_u_password").val(),
                username: $("#id_username").val(),
            },
            success: function success(data) {
                $("#id_username").css("border-color", "green");
                window.location.hash = "#sys-set";
            },
            error: function error(date) {
                $("#id_username").css("border-color", "red");
                $("#popup3 input").css("border-color", "red");
            },
        });
    });

    $("body").delegate(".set-not-push", "click", function () {
        var element = $(this);
        $.ajax({
            url: "/profile/mysettings/change_notf/",
            type: "POST",
            data: {
                type: "push",
                code: element.parent().attr("data-type"),
                value: element.val(),
            },
        });
    });
    $("body").delegate(".set-not-email", "click", function () {
        var element = $(this);
        $.ajax({
            url: "/profile/mysettings/change_notf/",
            type: "POST",
            data: {
                type: "email",
                code: element.parent().attr("data-type"),
                value: element.val(),
            },
        });
    });
    $("body").delegate(".set-priv", "click", function () {
        var element = $(this);
        $.ajax({
            url: "/profile/mysettings/change_priv/",
            type: "POST",
            data: {
                code: element.parent().attr("data-type"),
                value: element.val(),
            },
        });
    });

    //////////////////////////
    //Остальные обработчики///
    //////////////////////////

    var artist_page = 0;
    var friend_page = 1;
    var friend_offset = 0;
    var filtered_friend_page = 1;
    var filtered_friend_offset = 1;
    var my_band_page = 1;

    $("#delete-friend-request").click(function () {
        var element = $(this);

        $(this).parent().removeClass("show");

        $.ajax({
            url: "/profile/myfriends/delete_friend_request/",
            method: "POST",
            data: {
                id: $(this).attr("data-id"),
            },
            success: function success(data) {
                element.closest(".friend-block").hide();
            },
        });

        return false;
    });

    $("#hide-all-artists").click(function () {
        var elem = $(this);
        if (elem.attr("data-type") == "hide") {
            elem.parent()
                .parent()
                .find(".band-block")
                .each(function (i) {
                    if (i > 2) {
                        $(this).parent().hide();
                    }
                });
            elem.attr("data-type", "show");
            elem.text(localized("Show"));
        } else {
            elem.parent()
                .parent()
                .find(".band-block")
                .each(function (i) {
                    $(this).parent().show();
                });
            elem.attr("data-type", "hide");
            elem.text(localized("Hide"));
        }
        return false;
    });

    var popular_bands_ontour_only = false;
    var popular_bands_alphabetic_order = false;

    function load_popular_artists_page() {
        artist_page = artist_page + 1;

        if (artist_page >= 1) $("#hide-more-bands").css("display", "block");
        else $("#hide-more-bands").css("display", "none");

        var limit = artist_page > 0 ? 12 : 3;
        var is_first_page = artist_page <= 1;

        var old_url = window.location.href;

        var url = old_url.substring(0, old_url.indexOf("?"));

        //var url = window.location.href.replace('#', '') + "moreartists/?page=" + artist_page + "&ontour=" + popular_bands_ontour_only + "&alpha=" + popular_bands_alphabetic_order;
        var url =
            url.replace("#", "") +
            "moreartists/?page=" +
            artist_page +
            "&ontour=" +
            popular_bands_ontour_only +
            "&alpha=" +
            popular_bands_alphabetic_order;

        $.get(url, function (data) {
            if (
                $(data).find(".band-block").addBack().filter(".band-block")
                    .length < limit
            ) {
                $("#show-all-artists").hide();
            }
            if (is_first_page) {
                $(".popular-band-result").remove();
            }
            $(data).insertBefore("#more-bands");
            $("#show-all-artists").removeClass("pulse");
        });
    }

    $("#show-all-artists").click(function () {
        $(this).addClass("pulse");
        $("#show-all-artists").css({
            top: $(this).offset().top + $(this).height() + "px",
        });
        load_popular_artists_page();
        return false;
    });

    $(".popular-bands__button_ontour").click(function () {
        artist_page = -1;
        popular_bands_ontour_only = !popular_bands_ontour_only;
        load_popular_artists_page();

        $("#show-all-artists").css("display", "block");

        return false;
    });

    $(".popular-bands__button_alpha").click(function () {
        artist_page = -1;
        if (popular_bands_alphabetic_order == false) {
            popular_bands_alphabetic_order = !popular_bands_alphabetic_order;
        }
        load_popular_artists_page();
        return false;
    });

    $(".popular-bands__button_rate").click(function () {
        artist_page = -1;
        if (popular_bands_alphabetic_order == true) {
            popular_bands_alphabetic_order = !popular_bands_alphabetic_order;
        }
        load_popular_artists_page();
        return false;
    });

    /*$('.popular-bands__button').click(function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
        } else {
            $(this).addClass('active');
        }
    });*/

    $("#show-more-friends").click(function () {
        var temp = $(this).html();
        var elem = $(this);
        elem.attr("disabled", true);
        elem.html('<div class="cp-spinner cp-boxes spin-animation"></div>');
        switch ($("#show-more-friends").attr("data-type")) {
            case "my-friends":
                $.ajax({
                    url: window.location.href.replace("#", "") + "morefriends/",
                    method: "POST",
                    data: {
                        page: friend_page + 1,
                        offset: friend_offset,
                        term: $(".search_city").val(),
                    },
                    success: function success(data) {
                        if (data.more_events_to_load) {
                            $("#show-more-friends").show();
                        } else {
                            $("#show-more-friends").hide();
                        }
                        elem.html(temp);
                        elem.attr("disabled", false);
                        $(data.html).insertAfter($('[id^="friend"]').last());
                        friend_page = friend_page + 1;
                    },
                });
                break;
            case "my-friends-request-out":
                $.ajax({
                    url: window.location.href.replace("#", "") + "more/",
                    method: "POST",
                    data: {
                        page: friend_page + 1,
                        offset: friend_offset,
                    },
                    success: function success(data) {
                        if (data.more_events_to_load) {
                            $("#show-more-friends").show();
                        } else {
                            $("#show-more-friends").hide();
                        }
                        elem.html(temp);
                        elem.attr("disabled", false);
                        $(data.html).insertAfter($('[id^="friend"]').last());
                        friend_page = friend_page + 1;
                    },
                });
                break;
            case "my-friends-request-in":
                $.ajax({
                    url: window.location.href.replace("#", "") + "more/",
                    method: "POST",
                    data: {
                        page: friend_page + 1,
                        offset: friend_offset,
                    },
                    success: function success(data) {
                        if (data.more_events_to_load) {
                            $("#show-more-friends").show();
                        } else {
                            $("#show-more-friends").hide();
                        }
                        elem.html(temp);
                        elem.attr("disabled", false);
                        $(data.html).insertAfter($('[id^="friend"]').last());
                        friend_page = friend_page + 1;
                    },
                });
                break;
            default:
                $.ajax({
                    url: "/profile/myfriends/search/",
                    method: "POST",
                    data: {
                        page: filtered_friend_page + 1,
                        offset: filtered_friend_offset,
                        q_username: $("#p-mf-search").val(),
                    },
                    success: function success(data) {
                        if (data.more_events_to_load) {
                            $("#show-more-friends").show();
                        } else {
                            $("#show-more-friends").hide();
                        }
                        elem.html(temp);
                        elem.attr("disabled", false);
                        $(data.html).insertAfter($('[id^="friend"]').last());
                        filtered_friend_page = filtered_friend_page + 1;
                    },
                });
                break;
        }
        return false;
    });

    $("#pou-friend-search").keyup(function () {
        friend_page = 1;
        $.ajax({
            url: "filtered/",
            method: "POST",
            data: {
                term: $(this).val(),
            },
            success: function success(data) {
                $("#more-friends").html(data.html);
                if (data.more_events_to_load) {
                    $("#show-more-friends").show();
                } else {
                    $("#show-more-friends").hide();
                }
            },
        });
    });

    $("body").delegate(
        ".friend-block__link, .city__track-link",
        "click",
        function () {
            var el = $(this);

            // Отправить заявку в друзья
            if (el.attr("data-type") === "add") {
                var req_data = {
                    id: el.attr("data-id"),
                };
                $.post(
                    "/profile/myfriends/send_friend_invite/",
                    req_data,
                    function (data) {
                        //console.log(data);
                        //console.log(req_data);
                    }
                );
                el.attr("disabled", true);
                el.attr("data-type", "sended");
                el.text(localized("Sent"));

                // Принять заявку в друзья
            } else if (el.attr("data-type") === "received") {
                var req_data = {
                    id: el.attr("data-id"),
                };
                $.post(
                    "/profile/myfriends/accept_friend_request/",
                    req_data,
                    function (data) {}
                );
                el.attr("disabled", true);
                el.attr("data-type", "sended");
                el.text(localized("Accepted!"));

                // Отклонить заявку в друзья
            } else if (el.attr("data-type") === "decline") {
                var req_data = {
                    id: el.attr("data-id"),
                };
                $.post(
                    "/profile/myfriends/delete_friend_request/",
                    req_data,
                    function (data) {}
                );
                el.attr("disabled", true);
                el.attr("data-type", "sended");
                el.text(localized("Declined!"));

                // My cities add
            } else if (el.attr("data-type") === "mc-add") {
                var req_data = {
                    id: el.attr("data-id"),
                };

                $.ajax({
                    url: "/profile/mycities/change/",
                    method: "POST",
                    data: {
                        city: el.attr("data-id"),
                        type: el.attr("data-type"),
                    },
                    success: function success(data) {
                        el.attr("data-type", "mc-rem");
                        var newTitle = el.attr("data-alt-action");
                        el.attr("data-alt-action", el.text());
                        el.addClass("friend-block__city-link_selected");
                        el.addClass("active");
                        el.text(newTitle);
                        el.parent()
                            .find(".friend-block__friends_number")
                            .text(
                                Number(
                                    el
                                        .parent()
                                        .find(".friend-block__friends_number")
                                        .text()
                                ) + 1
                            );
                        $("#pc-cities").text(
                            Number($("#pc-cities").text()) + 1
                        );
                    },
                });

                // My cities remove
            } else if (el.attr("data-type") === "mc-rem") {
                var req_data = {
                    id: el.attr("data-id"),
                };

                $.ajax({
                    url: "/profile/mycities/change/",
                    method: "POST",
                    data: {
                        city: el.attr("data-id"),
                        type: el.attr("data-type"),
                    },
                    success: function success(data) {
                        el.attr("data-type", "mc-add");
                        var newTitle = el.attr("data-alt-action");
                        el.attr("data-alt-action", el.text());
                        el.removeClass("friend-block__city-link_selected");
                        el.removeClass("active");
                        el.text(newTitle);
                        el.parent()
                            .find(".friend-block__friends_number")
                            .text(
                                Number(
                                    el
                                        .parent()
                                        .find(".friend-block__friends_number")
                                        .text()
                                ) - 1
                            );
                        $("#pc-cities").text(
                            Number($("#pc-cities").text()) - 1
                        );
                    },
                });
            }
            return false;
        }
    );

    $("#p-mf-search").keyup(function () {
        $("#show-more-friends").attr("data-type", "filtered-friends");
        $.post(
            "/profile/myfriends/search/",
            $("#p-mf-search").serialize(),
            function (data) {
                if (data.more_events_to_load) {
                    $("#show-more-friends").show();
                } else {
                    $("#show-more-friends").hide();
                }
                $("#more-friends").html(data.html);
                filtered_friend_page = 1;
                filtered_friend_offset = 0;
            }
        );
        return false;
    });

    $("body").delegate("#delete-friend", "click", function () {
        var req_data = {
            id: $(this).attr("data-id"),
        };
        $.post("/profile/myfriends/delete_friend/", req_data, function (data) {
            friend_offset += 1;
        });
    });

    /*
        .gonnago-event.maybe
        */

    $("body").delegate(".gonnago-event.maybe", "click", function (e) {
        var el = $(this);
        if (!el.hasClass("selected")) {
            if ($(".event-page__track").hasClass("event-page__track_yes")) {
                //$('.event-page__track').trigger('click');
            }
        }
    });

    /*
        .gonnago-event.definitely
        */

    $("body").delegate(".gonnago-event.definitely", "click", function (e) {
        var el = $(this);
        if (!el.hasClass("unselected") && el.hasClass("selected")) {
            if ($(".event-page__track").hasClass("event-page__track_yes")) {
                $(".event-page__track").trigger("click");
            }
        }
    });

    // Clicking on "Going" and "Interested" widgets.

    $("body").delegate(
        ".gonnago-event.maybe, .gonnago-event.definitely",
        "click",
        function (e) {
            var el = $(this);

            var eventId = el.attr("data-event-id");
            var nextStatus = el.hasClass("selected")
                ? 0
                : $(this).attr("data-status");

            if (eventId !== "") {
                updateGoingStatus(eventId, nextStatus, el);
            }
        }
    );

    /*  скрыть выпадающее меню, при клике вне его
     */

    $(document).mouseup(function (e) {
        var container = $(".statuses-menu");
        if (container.has(e.target).length === 0) {
            container.hide();
        }
    });

    // Just pressing the "Interested", "Going" or "Remind me" widget.

    $("body").delegate(
        ".statuses-item-maybe, .statuses-item-definitely",
        "click",
        function (e) {
            var el = $(this);
            var eventId = el.attr("data-event-id");

            // Maybe show menu?
            if (el.hasClass("statuses__arrow")) {
                el.parent().parent().find(".statuses-menu").show();
                return;
            }

            // Otherwise just toggle
            var nextStatus = el.hasClass("selected")
                ? 0
                : $(this).attr("data-status");

            if (!el.hasClass("signin__noauth") && eventId !== "") {
                updateGoingStatus(eventId, nextStatus, el);
            }
        }
    );

    function updateGoingStatus(eventId, nextStatus, origin) {
        if (origin) {
            origin.addClass("pulse");
        }
        $.ajax({
            url: "/profile/gonnago/",
            method: "POST",
            data: {
                event_id: eventId,
                status: nextStatus,
            },
            success: function success(data) {
                $(
                    `.statuses-item-definitely[data-event-id=${eventId}] .button-count`
                ).text(data.new_counts.definitely);
                $(
                    `.statuses-item-maybe[data-event-id=${eventId}] .button-count`
                ).text(data.new_counts.maybe);

                let hasMaybe = $(
                    `.statuses-menu-item[data-event-id=${eventId}].maybe`
                ).length;

                $(
                    `.statuses-item-definitely[data-event-id=${eventId}], .statuses-item-maybe[data-event-id=${eventId}]`
                ).each((i, el) => {
                    let widget = $(el);
                    if (widget.attr("data-status") == nextStatus) {
                        widget.show();
                        widget.addClass("selected");
                        if (hasMaybe) {
                            widget.addClass("statuses__arrow");
                        }
                    } else {
                        if (nextStatus > 0) {
                            widget.hide();
                        } else {
                            widget.show();
                        }
                        widget.removeClass("selected");
                        widget.removeClass("statuses__arrow");
                    }
                });

                $(`.statuses-menu-item[data-event-id=${eventId}]`).each(
                    function () {
                        let menuItem = $(this);
                        if (menuItem.attr("data-status") == nextStatus) {
                            menuItem.addClass("selected");
                        } else {
                            menuItem.removeClass("selected");
                        }
                    }
                );
            },
            complete: function complete(data) {
                if (origin) {
                    origin.removeClass("pulse");
                }
                $(".status-menu-" + eventId).css("display", "none");
            },
        });
    }

    /*  .statuses-menu-item.maybe, .statuses-menu-item.definitely, .statuses-menu-item.notinterested
        пункты выпадающего меню
    */

    $("body").delegate(
        ".statuses-menu-item.maybe, .statuses-menu-item.definitely, .statuses-menu-item.notinterested",
        "click",
        function (e) {
            var el = $(this);
            var eventId = el.attr("data-event-id");

            if (el.hasClass("selected")) {
                $(".status-menu-" + eventId).css("display", "none");
                return;
            }

            var nextStatus = $(this).attr("data-status");

            if (!el.hasClass("signin__noauth") && eventId !== "") {
                updateGoingStatus(eventId, nextStatus, el);
            }
        }
    );

    /*  при вкл. "найти компанию":
            1. вкл. кнопку "точно пойду"
            2. если было "возможно пойду" то выкл.

        при выкл. "найти компанию":
            1. "точно пойду" не выключается

        при выкл. "точно пойду" (или вкл "возможно иду"):
            1. "найти компанию" отключается */

    $("body").delegate(".event-page__track", "click", function () {
        if ($(this).hasClass("event-page__track_yes")) {
            $(this).removeClass("event-page__track_yes");
        } else {
            $(this).addClass("event-page__track_yes");
        }

        var el = $(this);
        var eventId = el.attr("data-event-id");
        var nextStatus = el.hasClass("event-page__track_yes") ? 1 : 0;

        if (eventId !== "") {
            $.ajax({
                url: "/profile/gonnago/",
                method: "POST",
                data: {
                    event_id: eventId,
                    company: nextStatus,
                },
                success: function success(data) {
                    if (data.changed && nextStatus) {
                        el.addClass("event-page__track_yes");
                        if (
                            !$(
                                ".event-page-past__content .gonnago-event.definitely"
                            ).hasClass("selected")
                        ) {
                            $(
                                ".event-page-past__content .gonnago-event.definitely"
                            ).trigger("click");
                        }
                    } else {
                        el.removeClass("event-page__track_yes");
                    }
                },
            });
        }
    });

    $(".artist-subscribe-button").each((_index, elDom) => {
        const el = $(elDom);
        const attr = el.hasClass("active")
            ? "text-subscribed"
            : "text-unsubscribed";
        el.find("span").html(el.attr(attr));
    });

    $("body").delegate(".artist-subscribe-button", "click", function () {
        const artistId = $(this).data("id");
        const el = $(this);
        const wasSubscribed = $(this).hasClass("active");
        el.addClass("pulse");

        $.ajax({
            url: "/profile/set_subscription_to_artist/",
            method: "POST",
            data: {
                artist_id: artistId,
                status: wasSubscribed ? 0 : 1,
            },
            success: function success(data) {
                el.toggleClass("active", data.status);
                el.removeClass("pulse");
                const attr = data.status
                    ? "text-subscribed"
                    : "text-unsubscribed";
                el.find("span").html(el.attr(attr));
                $(`.subscribe-block-subcribers[data-id="${artistId}"]`).html(
                    data.html
                );
            },
        });
    });

    $("#sgbg").click(function () {
        load_page(
            "/profile/get_filtered_groups/",
            "#pagination-id",
            "#sgbg",
            ".results__block"
        );
    });

    function load_bands() {
        var ids = $("#sgbg-select")
            .select2("data")
            .map(function (item) {
                return {
                    id: item.id,
                };
            });
        if (!ids.length) {
            return;
        }
        $.ajax({
            url: "/profile/get_filtered_groups/",
            method: "GET",
            data: {
                genres_or_bands: JSON.stringify(ids),
                ordering: current_sgbg_ordering,
            },
            success: function success(data) {
                $("#nextToProfile").show();
                $(".results__block").html(data);
                if (data.length > 3) {
                    $("#sgbg").show();
                } else {
                    $("#sgbg").hide();
                }

                $("#sgbg").prop("disabled", false);
                $("#pagination-id").val(2);
                $("#sgbg").text("Показать еще");
                if (data.length < 5) {
                    $(".results__block").html(
                        ' <a class="groups-search__skip" type="button" href="/profile/myshows/">Пропустить</a>'
                    );
                }
            },
        });
    }

    $("#sgbg-select").on("change.select2", load_bands);

    var current_sgbg_ordering = "alpha";

    $(".groups-order-alpha").click(function () {
        current_sgbg_ordering = "alpha";
        $(".groups-order-alpha").addClass("groups-order-current");
        $(".groups-order-rating").removeClass("groups-order-current");
        load_bands();
    });
    $(".groups-order-rating").click(function () {
        current_sgbg_ordering = "rating";
        $(".groups-order-alpha").removeClass("groups-order-current");
        $(".groups-order-rating").addClass("groups-order-current");
        load_bands();
    });

    $("#sgbg-select").select2({
        minimumInputLength: 3,
        ajax: {
            url: "/profile/search_group_by_genre/",
            method: "POST",
            data: function data(params) {
                var query = {
                    search: params.term,
                };
                return query;
            },
        },
    });

    try {
        var active_dates = JSON.parse($("#calendar-dates").text());
    } catch (e) {
        var active_dates = { events_by_date: {} };
    }

    var lastSelectedDate = null;

    const calendarParams = (jqueryCalendar) => ({
        ...window.calendarLangParams,
        dateFormat: "yy-mm-dd",
        firstDay: 1,
        showOtherMonths: true,
        onSelect: function (date, inst) {
            if (date == lastSelectedDate && $(".main-tooltip").length) {
                jqueryCalendar.tooltipster("close");
                return;
            }
            lastSelectedDate = date;
            jqueryCalendar
                .tooltipster(
                    "content",
                    '<div class="cp-spinner cp-boxes spin-animation"></div>'
                )
                .tooltipster("open");
            $.ajax({
                type: "GET",
                url: "/profile/calendarevents/?date=" + date,
                success: function success(data) {
                    jqueryCalendar
                        .tooltipster("content", data)
                        .tooltipster("open");
                },
            });
        },
        beforeShowDay: (date) => {
            var d = date;
            var formattedDate = d.toLocaleString("sv").slice(0, 10); // YYYY-MM-DD
            var events = active_dates.events_by_date[formattedDate];

            // type: "pastevent" - прошедшее событие (синий)
            // type: "gonnago" - точно пойду (красное)
            // type: "track" - интересуюсь (красное)
            // type: "haveticket" - есть билет (зеленый)
            // type: "cancelled" - отмененное (красное)

            var classes = [];
            if (events) {
                if (events.some((e) => e.type == "cancelled")) {
                    classes.push("dayHasEventCancelled");
                }
                if (events.some((e) => e.type == "pastevent")) {
                    classes.push("dayHasEventInThePast");
                }
                if (events.some((e) => e.type == "track")) {
                    classes.push("dayHasEvent");
                }
                if (events.some((e) => e.type == "gonnago")) {
                    classes.push("dayGonnagoEvent");
                }
                if (events.some((e) => e.type == "haveticket")) {
                    classes.push("dayHasTicket");
                }
            }
            return [true, classes.join(" ")];
        },
    });
    const calendar = $(".user__calendar");
    calendar.datepicker(calendarParams(calendar));

    calendar.tooltipster({
        theme: "tooltipster-punk",
        animation: "fade",
        position: "left",
        contentAsHTML: true,
        interactive: true,
        content: '<div class="cp-spinner cp-boxes spin-animation"></div>',
        trigger: "custom",
        triggerClose: {
            mouseleave: true,
            click: true,
        },
    });

    for (let i = 0; i < 12; i++) {
        let defaultDate = new Date();
        defaultDate.setDate(1);
        defaultDate.setMonth(i);
        const calendar = $("#user__calendar__month" + (i + 1));
        calendar.datepicker(calendarParams(calendar));
        calendar.tooltipster({
            theme: "tooltipster-punk",
            animation: "fade",
            position: "left",
            contentAsHTML: true,
            interactive: true,
            content: '<div class="cp-spinner cp-boxes spin-animation"></div>',
            trigger: "custom",
            triggerClose: {
                mouseleave: true,
                click: true,
            },
        });
    }

    const repositionAreaSelectPopupAndBeak = (button, popup) => {
        const buttonCenter = button.offset().left + button.outerWidth() / 2;
        const beak = popup.find(".beak");
        const beakCenter = beak.offset().left + beak.outerWidth() / 2;
        const currentLeft = parseInt(beak.css("left"));
        beak.css("left", `${currentLeft - beakCenter + buttonCenter}px`);
        if (window.matchMedia("(max-width: 480px)").matches) {
            const popupCenter = popup.offset().left + popup.outerWidth() / 2;
            const screenCenter = $(window).width() / 2;
            const currentLeft = parseInt(popup.css("left"));
            popup.css("left", `${currentLeft + screenCenter - popupCenter}px`);
        }
    };

    $(".distance-clickable").click(function () {
        const button = $(this);
        const container = button.parent();
        container.find(".location-select").removeClass("show");
        const popup = container.find(".distance-select");
        if (popup.hasClass("show")) {
            popup.removeClass("show");
        } else {
            popup.addClass("show");
            repositionAreaSelectPopupAndBeak(button, popup);
        }
    });

    $(".location-clickable, .geolocation__icon").click(function () {
        const button = $(this);
        const container = button.parent();
        container.find(".distance-select").removeClass("show");
        const popup = container.find(".location-select");
        if (popup.hasClass("show")) {
            popup.removeClass("show");
        } else {
            popup.addClass("show");
            repositionAreaSelectPopupAndBeak(button, popup);
        }
    });

    $("body").delegate(".info__open-lineup", "click", function () {
        if ($(this).parent().find(".lineup-hidden").hasClass("show")) {
            $(this).removeClass("info__open-lineup-activated");
            $(this).parent().find(".lineup-hidden").removeClass("show");
        } else {
            $(this).addClass("info__open-lineup-activated");
            $(this).parent().find(".lineup-hidden").addClass("show");
        }
    });

    $("body").delegate(".ticket-state__switch", "click", function () {
        var event_id = $(this).attr("data-event-id");
        var elements = document.querySelectorAll(
            'div.ticket-state__switch[data-event-id="' + event_id + '"]'
        );
        var element1, element2, parent, st;

        element1 = $(elements["0"]);
        element2 = $(elements["1"]);

        parent1 = $(element1).parent().parent();
        parent2 = $(element2).parent().parent();

        st = null;

        $.ajax({
            method: "POST",
            url: "/profile/havticket/",
            data: { event: event_id },
            success: function success(data) {
                if (
                    element1.hasClass("ticket-state__switch_yes") ||
                    element2.hasClass("ticket-state__switch_yes")
                ) {
                    st = true;
                    parent1.html(data);
                    parent2.html(data);
                } else {
                    st = false;
                }
                parent1.html(data);
                parent2.html(data);
            },
        });
    });

    $("body").delegate(".ticket-state-calendar__switch", "click", function () {
        var event_id = $(this).attr("data-event-id");
        var element = $(this);
        var el = element;

        //var element_outer = $("#change_state_"+event_id);

        //var parent = $(this).parent().parent();
        //console.log(parent);

        var st = null;

        if (el.hasClass("ticket-state-calendar__switch_yes")) {
            el.addClass("unselected");
        } else {
            el.removeClass("unselected");
        }

        $.ajax({
            method: "POST",
            url: "/profile/havticket/",
            data: { event: event_id },
            success: function success(data) {
                if (element.hasClass("ticket-state-calendar__switch_yes")) {
                    element.removeClass("ticket-state-calendar__switch_yes");
                    element.addClass("ticket-state-calendar__switch_no");

                    $("#change_state_" + event_id + " .ticket-state_has").css(
                        "display",
                        "none"
                    );
                    $(
                        "#change_state_" + event_id + " .ticket-state_has-no"
                    ).css("display", "flex");

                    st = true;
                    //$(this).html(data);
                } else {
                    element.removeClass("ticket-state-calendar__switch_no");
                    element.addClass("ticket-state-calendar__switch_yes");

                    $("#change_state_" + event_id + " .ticket-state_has").css(
                        "display",
                        "flex"
                    );
                    $(
                        "#change_state_" + event_id + " .ticket-state_has-no"
                    ).css("display", "none");

                    st = false;
                }
                //$(this).html(data);
            },
        });
    });

    ////////////////////
    //глобальный поиск//
    ///////////////////

    var EventEmitterGlobalSearch = {
        //События
        _events: {},
        dispatch: function dispatch(event, data) {
            if (!this._events[event]) return;
            for (var i = 0; i < this._events[event].length; i++) {
                this._events[event][i](data);
            }
        },
        subscribe: function subscribe(event, callback) {
            if (!this._events[event]) this._events[event] = [];
            this._events[event].push(callback);
        },
    };

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0)
                return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    var GlobalSearchStorage = {
        _data: {
            type: "",
            page: 1,
            prev: false,
            next: false,
            q: "",
            block: false,
            data: "",
            buttons: false,
        },

        update: function (data) {
            var diff;

            for (var key in data) {
                if (this._data.hasOwnProperty(key)) {
                    if (this._data[key] != data[key]) {
                        diff = true;
                        this._data[key] = data[key];
                    }
                }
            }

            if (diff) {
                EventEmitterGlobalSearch.dispatch("displayUpdated", this._data);
            }
        },

        getData: function () {
            return this._data;
        },

        getContent: function () {
            return this._data.data;
        },

        getPage: function () {
            return this._data.page;
        },

        getType: function () {
            return this._data.type;
        },
        setType: function (val) {
            this._data.type = val;
            EventEmitterGlobalSearch.dispatch("queryUpdated", this._data);
        },

        hasPrev: function () {
            return this._data.prev;
        },

        setHasPrev: function (val) {
            this._data.prev = val;
        },

        hasNext: function () {
            return this._data.next;
        },

        setHasNext: function (val) {
            this._data.next = val;
            EventEmitterGlobalSearch.dispatch("displayUpdated", this._data);
        },

        nextPage: function () {
            this._data.page += 1;
            EventEmitterGlobalSearch.dispatch("queryUpdated", this._data);
        },

        prevPage: function () {
            if (this._data.page > 1) {
                this._data.page -= 1;
            } else {
                this._data.page = 1;
            }
            EventEmitterGlobalSearch.dispatch("queryUpdated", this._data);
        },

        isVisible: function () {
            return this._data.block;
        },

        hideState: function () {
            this._data.block = false;
            EventEmitterGlobalSearch.dispatch("displayUpdated", this._data);
        },

        showState: function () {
            this._data.block = true;
            EventEmitterGlobalSearch.dispatch("displayUpdated", this._data);
        },

        updateQuery: function (val) {
            var diff = this._data.q != val;
            this._data.q = val;
            this._data.page = 1;
            if (diff && (this._data.q.length > 1 || this._data.q.toLowerCase() == "ы")) {
                EventEmitterGlobalSearch.dispatch("queryUpdated", this._data);
            }
        },

        getQuery: function () {
            return this._data.q;
        },

        showButtonsState: function () {
            this._data.buttons = true;
        },

        hideButtonsState: function () {
            this._data.buttons = false;
        },

        getButtons: function () {
            return this._data.buttons;
        },
    };

    var gsr = null;

    function globalSearchRequest() {
        EventEmitterGlobalSearch.dispatch("displayUpdated", this._data);

        if (gsr) {
            gsr.abort();
        }

        const query = GlobalSearchStorage.getQuery().trim();

        if (query.length < 1) {
            return false;
        }

        setTimeout(function () {
            $(".timetable__spinner").addClass("visible");
            gsr = $.ajax({
                url: "/globalsearch/",
                method: "POST",
                data: {
                    q: query,
                    type: GlobalSearchStorage.getType(),
                    page: GlobalSearchStorage.getPage(),
                    is_mobile: $(window).width() > 575 ? 0 : 1,
                },
                success: function success(data) {
                    var tmp = {
                        data: data.data,
                        prev: data.prev,
                        next: data.next,
                        buttons: data.prev || data.next,
                    };
                    GlobalSearchStorage.update(tmp);
                },
                complete: function () {
                    $(".timetable__spinner").removeClass("visible");
                },
            });
        }, 400);
    }

    EventEmitterGlobalSearch.subscribe("queryUpdated", globalSearchRequest);

    //Рисовалки

    function displaySearch() {
        if (GlobalSearchStorage.isVisible()) {
            //$('.header .header-global-search__block').show();
        } else {
            //$('.header .header-global-search__block').hide();
        }
    }

    function displayHeaderSerach() {
        if (GlobalSearchStorage.isVisible()) {
            $(".header .search_on_header").show();
            $(".header .geolocation__search").removeClass("show");
        } else {
            //$('.header .search_on_header').hide();
        }
    }

    function activeType() {
        var elements = $("button.searchtype");
        elements.removeClass("active");
        elements.each(function (index) {
            if ($(this).attr("data-type") === GlobalSearchStorage.getType()) {
                $(this).addClass("active");
            }
        });

        $("button.searchtype").click(function () {
            GlobalSearchStorage.setType($(this).attr("data-type"));
        });
    }

    function drawContent() {
        $(".header .global-search__content").html(
            GlobalSearchStorage.getContent()
        );
    }

    function drawButton() {
        if (GlobalSearchStorage.hasNext()) {
            $(".global-search-block__btn.next").removeAttr("disabled");
            $(".global-search-block__btn.next").css("display", "block");
        } else {
            $(".global-search-block__btn.next").attr("disabled", "disabled");
            $(".global-search-block__btn.next").css("display", "none");
        }
        if (GlobalSearchStorage.hasPrev()) {
            $(".global-search-block__btn.prev").removeAttr("disabled");
            $(".global-search-block__btn.prev").css("display", "block");
        } else {
            $(".global-search-block__btn.prev").attr("disabled", "disabled");
            $(".global-search-block__btn.prev").css("display", "none");
        }

        if (GlobalSearchStorage.getButtons()) {
            $(".global-search-block__btn-group").show();
        } else {
            $(".global-search-block__btn-group").hide();
        }
    }

    EventEmitterGlobalSearch.subscribe("displayUpdated", drawButton);
    EventEmitterGlobalSearch.subscribe("displayUpdated", drawContent);
    EventEmitterGlobalSearch.subscribe("displayUpdated", activeType);
    EventEmitterGlobalSearch.subscribe("displayUpdated", displaySearch);
    EventEmitterGlobalSearch.subscribe("displayUpdated", displayHeaderSerach);

    //Клики

    $(".header .header__button").click(function () {
        if ($(this).parent().hasClass("header__button__outer")) {
            $(this).parent().removeClass("header__button__outer");
            $(this).parent().addClass("header__button__outer-without_gradient");
        } else {
            $(this).parent().addClass("header__button__outer");
            $(this)
                .parent()
                .removeClass("header__button__outer-without_gradient");
        }

        if ($(".header .search_on_header").css("display") === "block") {
            //GlobalSearchStorage.hideState();
        } else {
            GlobalSearchStorage.showState();
        }

        if ($(".header .header-global-search__block").is(":visible")) {
            //GlobalSearchStorage.hideState();
        } else {
            //GlobalSearchStorage.showState();
            $(".header input.header__input.global__search").focus();
            $(".header .header-global-search__block").hide();
        }
    });

    $(".header .header__input").change(function () {
        GlobalSearchStorage.updateQuery($(this).val());
    });

    $(".header .global-search-block__btn.next").click(function () {
        if ($(this).is(":enabled")) {
            GlobalSearchStorage.nextPage();
        }
    });

    $(".header .global-search-block__btn.prev").click(function () {
        if ($(this).is(":enabled")) {
            GlobalSearchStorage.prevPage();
        }
    });

    //////////////////////
    //Обновление настроек
    /////////////////////

    var EventEmitterUpdateSettings = {
        //События
        _events: {},
        dispatch: function dispatch(event, data) {
            if (!this._events[event]) return;
            for (var i = 0; i < this._events[event].length; i++) {
                this._events[event][i](data);
            }
        },
        subscribe: function subscribe(event, callback) {
            if (!this._events[event]) this._events[event] = [];
            this._events[event].push(callback);
        },
    };

    var UpdateSettingsStorage = {
        _data: {
            need_pass: true,
            us__email: null,
            us__pass: null,
            us__pass2: null,
            us__name: null,
            us__nickname: null,
            us__borndate: null,
            sex: null,
            actual: false,
            errors: false,
        },
        update: function (data) {
            prev_data = JSON.stringify(this._data);
            for (var key in data) {
                if (this._data.hasOwnProperty(key)) {
                    this._data[key] = data[key];
                }
            }

            if (prev_data !== JSON.stringify(this._data)) {
                EventEmitterUpdateSettings.dispatch("dataUpdated", this._data);
            }
        },

        getData: function () {
            return this._data;
        },
        needPass: function () {
            return this._data.need_pass;
        },
        passwordIsNeed: function () {
            this._data.need_pass = true;
        },
        passwordIsNotNeed: function () {
            this._data.need_pass = false;
        },
        setEmail: function (email) {
            this._data.us__email = email;
            EventEmitterUpdateSettings.dispatch("dataUpdated", this._data);
        },
        getEmail: function () {
            return this._data.us__email;
        },
        setPass: function (pass) {
            this._data.us__pass = pass;
            EventEmitterUpdateSettings.dispatch("dataUpdated", this._data);
        },
        getPass: function () {
            return this._data.us__pass;
        },
        setName: function (name) {
            this._data.us__name = name;
            EventEmitterUpdateSettings.dispatch("dataUpdated", this._data);
        },
        getName: function () {
            return this._data.us__name;
        },
        setNickname: function (nickname) {
            this._data.us__nickname = nickname;
            EventEmitterUpdateSettings.dispatch("dataUpdated", this._data);
        },
        getNickname: function () {
            return this._data.us__nickname;
        },
        setBorndate: function (borndate) {
            this._data.us__borndate = borndate;
            EventEmitterUpdateSettings.dispatch("dataUpdated", this._data);
        },
        getBorndate: function () {
            return this._data.us__borndate;
        },
        setSex: function (sex) {
            this._data.sex = sex;
            EventEmitterUpdateSettings.dispatch("dataUpdated", this._data);
        },
        getSex: function () {
            return this._data.sex;
        },
        nowActual: function () {
            this._data.actual = true;
            EventEmitterUpdateSettings.dispatch("dataActual", this._data);
        },
        nowNotActual: function () {
            this._data.actual = false;
            EventEmitterUpdateSettings.dispatch("dataActual", this._data);
        },
        getErrorsState: function () {
            return this._data.errors;
        },
        hasErrors: function () {
            this._data.errors = true;
        },
        noErrors: function () {
            this._data.errors = false;
        },
    };

    //Сеть

    function updateSettingsRequest() {
        $.ajax({
            url: "/profile/update_info/",
            method: "POST",
            data: UpdateSettingsStorage.getData(),
            success: function (data) {
                var nameField = "";
                UpdateSettingsStorage.nowActual();
                for (var key in data.updatedFields) {
                    nameField = "#" + data.updatedFields[key];
                    $(nameField).removeClass("err");
                    $(nameField).addClass("suc");
                }
                $(".us__errors").hide();
                $(".us__input").removeClass("err");
                var errors_text = "";
                UpdateSettingsStorage.noErrors();
                for (var key in Object.keys(data.errors)) {
                    UpdateSettingsStorage.hasErrors();
                    $(".us__errors").show();
                    nameField = "#" + Object.keys(data.errors)[key];
                    $(nameField).removeClass("suc");
                    $(nameField).addClass("err");
                    errors_text +=
                        "<p>" +
                        data.errors[Object.keys(data.errors)[key]] +
                        "</p>";
                    $(".us__errors").html(errors_text);
                }
            },
        });
    }

    EventEmitterUpdateSettings.subscribe("dataUpdated", updateSettingsRequest);
    if ($("#us__pass").length) {
        UpdateSettingsStorage.passwordIsNeed();
    } else {
        UpdateSettingsStorage.passwordIsNotNeed();
    }

    //События
    $(".us__input").keyup(function () {
        UpdateSettingsStorage.nowNotActual();
        var element = $(this);
        var obj = {};
        obj[element.attr("id")] = element.val();
        UpdateSettingsStorage.update(obj);
    });
    $(".us__sex").click(function () {
        UpdateSettingsStorage.nowNotActual();
        UpdateSettingsStorage.setSex($(this).attr("data-value"));
    });
    $("#us__next").click(function () {
        if (
            UpdateSettingsStorage.needPass() &&
            UpdateSettingsStorage.getPass() == null
        ) {
            $(".us__errors").show();
            $(".us__errors").html($("#us__info").text());
            return false;
        }
        if (UpdateSettingsStorage.getErrorsState()) {
            $(".us__errors").show();
            $(".us__errors").html($("#us__info2").text());
            return false;
        }
        window.location.replace("/profile/search_group_by_genre/");
    });

    //Рисовалки

    //Диапазон годов (year_range) от -100 до -10 от текущей даты
    var d = new Date();
    var curr_year = d.getFullYear();
    var start_date = d.getFullYear() - 100;
    var end_date = d.getFullYear() - 10;
    var year_range = start_date + ":" + end_date;

    //Год по-умолчанию, текущий - 30
    var default_date = "01.01." + (curr_year - 30);
    var default_year = curr_year - 30;

    $("#us__borndate").datepicker({
        ...window.calendarLangParams,
        yearRange: year_range,
        dateFormat: "dd.mm.yy",
        defaultViewDate: { year: default_year, month: 1, day: 1 },
        defaultDate: default_date,
        changeMonth: true,
        changeYear: true,
        onSelect: function (selected, evnt) {
            UpdateSettingsStorage.nowNotActual();
            UpdateSettingsStorage.setBorndate(selected);
        },
    });

    $(".event-page__others.event-page__others_see-all").click(function () {
        $(".event-page__others.event-page__others_span").css("display", "none");
        $(
            ".event-page__others.event-page__others_span.event-page__others_span-se-all"
        ).css("display", "block");
    });

    $(".event-page__others.event-page__others_hide").click(function () {
        $(".event-page__others.event-page__others_span").css(
            "display",
            "block"
        );
        $(
            ".event-page__others.event-page__others_span.event-page__others_span-se-all"
        ).css("display", "none");
    });

    $(".similiar-slider").slick({
        TouchMove: true,
        touchThreshold: 300,
        accessibility: false,
        arrows: false,
        dots: false,
        draggable: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        waitForAnimate: true,
        responsive: [
            {
                breakpoint: 1190,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 525,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });
    $(".nearby-events").slick({
        TouchMove: true,
        touchThreshold: 300,
        accessibility: false,
        arrows: false,
        dots: false,
        draggable: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 525,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });
    $(".similiar-genres").slick({
        TouchMove: true,
        touchThreshold: 300,
        accessibility: false,
        arrows: false,
        dots: false,
        draggable: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        appendDots: $(".similiar-dots"),
        responsive: [
            {
                breakpoint: 1190,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 525,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });
    $(".similiar-event").slick({
        TouchMove: true,
        touchThreshold: 300,
        accessibility: false,
        arrows: false,
        dots: true,
        draggable: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        variableWidth: true,
        appendDots: $(".similiar-dots"),
        responsive: [
            {
                breakpoint: 1190,
                settings: {
                    variableWidth: false,
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 990,
                settings: {
                    variableWidth: false,
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 525,
                settings: {
                    variableWidth: false,
                    slidesToShow: 1,
                },
            },
        ],
    });
    $(".materials__main-block")
        .on("init", function (event, slick) {
            //init code goes here
        })
        .on("afterChange", function (e, o) {
            //on change slide = do action
            $("iframe").each(function () {
                $(this)[0].contentWindow.postMessage(
                    '{"event":"command","func":"' +
                        "stopVideo" +
                        '","args":""}',
                    "*"
                );
            });
        })
        .slick({
            TouchMove: true,
            touchThreshold: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,

            asNavFor: ".materials__thumb-block",
        });
    $(".materials__thumb-block").slick({
        TouchMove: true,
        touchThreshold: 300,
        accessibility: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        asNavFor: ".materials__main-block",
        dots: false,
        centerMode: true,
        focusOnSelect: true,
        variableWidth: true,
        prevArrow:
            '<img src="/static/img/theme/event-slider/left_light.svg_" class="slider-arrows slider-arrows__left">',
        nextArrow:
            '<img src="/static/img/theme/event-slider/right_light.svg_" class="slider-arrows slider-arrows__right">',
    });

    $("#content-images").slick({
        TouchMove: true,
        touchThreshold: 300,
        accessibility: false,
        slidesToShow: 8,
        slidesToScroll: 1,
        dots: false,
        prevArrow:
            '<img src="/static/img/theme/event-slider/left_light.svg_" class="event-arrows event-arrows__left">',
        nextArrow:
            '<img src="/static/img/theme/event-slider/right_light.svg_" class="event-arrows event-arrows__right">',
        responsive: [
            {
                breakpoint: 1190,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 460,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    });
    $("#wow-button").click(function () {
        setTimeout(function () {
            $("#content-images").slick({
                TouchMove: true,
                touchThreshold: 300,
                accessibility: false,
                slidesToShow: 8,
                slidesToScroll: 1,
                dots: false,
                prevArrow:
                    '<img src="/static/img/theme/event-slider/left_light.svg_" class="event-arrows event-arrows__left">',
                nextArrow:
                    '<img src="/static/img/theme/event-slider/right_light.svg_" class="event-arrows event-arrows__right">',
                responsive: [
                    {
                        breakpoint: 1190,
                        settings: {
                            slidesToShow: 6,
                        },
                    },
                    {
                        breakpoint: 990,
                        settings: {
                            slidesToShow: 4,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                ],
            });
        }, 500);
    });
    $("#rich-button").click(function () {
        setTimeout(function () {
            $("#content-feedback").slick({
                TouchMove: true,
                touchThreshold: 300,
                accessibility: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
                prevArrow:
                    '<img src="/static/img/theme/event-slider/left_light.svg_" class="event-arrows event-arrows__left">',
                nextArrow:
                    '<img src="/static/img/theme/event-slider/right_light.svg_" class="event-arrows event-arrows__right">',
                responsive: [
                    {
                        breakpoint: 990,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                ],
            });
        }, 500);
    });
    $("#content-images-event").slick({
        TouchMove: true,
        touchThreshold: 300,
        accessibility: false,
        slidesToShow: 8,
        slidesToScroll: 1,
        dots: false,
        variableWidth: true,
        prevArrow:
            '<img src="/static/img/theme/event-slider/left_light.svg_" class="event-arrows event-arrows__left event-arrows__left_event">',
        nextArrow:
            '<img src="/static/img/theme/event-slider/right_light.svg_" class="event-arrows event-arrows__right event-arrows__right_event">',
    });
    $("#content-feedback-event").slick({
        TouchMove: true,
        touchThreshold: 300,
        accessibility: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        variableWidth: true,
        prevArrow:
            '<img src="/static/img/theme/event-slider/left_light.svg_" class="event-arrows event-arrows__left event-arrows__left_event">',
        nextArrow:
            '<img src="/static/img/theme/event-slider/right_light.svg_" class="event-arrows event-arrows__right event-arrows__right_event">',
    });

    $(".gonna-slider").on("init", function (event, slick, direction) {
        if (!($(".gonna-slider .slick-slide").length > 5)) {
            $(".who-goes__arrow").hide();
        }
    });

    $(".gonna-slider").slick({
        TouchMove: true,
        touchThreshold: 300,
        accessibility: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1190,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    });

    $(".popular-platforms").slick({
        TouchMove: true,
        touchThreshold: 300,
        accessibility: false,
        arrows: false,
        dots: true,
        draggable: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        appendDots: $(".similiar-dots"),
        responsive: [
            {
                breakpoint: 1190,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 525,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });

    /*
        slider welcome page
        */

    $(".noauth__slider .first-slide > img").attr(
        "src",
        $(".noauth__slider .slick-current")
            .prev()
            .find(".event__image > img")
            .attr("src")
    );

    $("body").delegate(".noauth__slider .first-slide", "click", function () {
        $(".noauth__slider .slick-list *").removeClass("event__image-anim");
        $(".noauth__slider .slick-list *").removeClass("event__options-anim");
        $(".noauth__slider .first-slide > img").attr(
            "src",
            $(".noauth__slider .slick-current")
                .prev()
                .prev()
                .find(".event__image > img")
                .attr("src")
        );
        $(".noauth__slider .similiar-slider").slick("slickPrev");
        if (
            $(".noauth__slider .slick-current")
                .find(".event__image")
                .hasClass("event__image-anim")
        ) {
        } else {
            $(".noauth__slider .slick-current")
                .find(".event__image")
                .addClass("event__image-anim");
            $(".noauth__slider .slick-current")
                .find(".event__options")
                .addClass("event__options-anim");
        }
    });

    $("body").delegate(
        ".noauth__slider .first-slide",
        "mouseenter",
        function () {
            $(".noauth__slider .slick-list *").removeClass("event__image-anim");
            $(".noauth__slider .first-slide > img").attr(
                "src",
                $(".noauth__slider .slick-current")
                    .prev()
                    .prev()
                    .find(".event__image > img")
                    .attr("src")
            );
            $(".noauth__slider .similiar-slider").slick("slickPrev");
            if (
                $(".noauth__slider .slick-current")
                    .find(".event__image")
                    .hasClass("event__image-anim")
            ) {
            } else {
                $(".noauth__slider .slick-current")
                    .find(".event__image")
                    .addClass("event__image-anim");
                $(".noauth__slider .slick-current")
                    .find(".event__options")
                    .addClass("event__options-anim");
            }
        }
    );

    $(".profile__slider .last-slide > img").attr(
        "src",
        $(".profile__slider .slick-current")
            .next()
            .next()
            .next()
            .find(".event__image > img")
            .attr("src")
    );

    $("body").delegate(".profile__slider .last-slide", "click", function () {
        $(".profile__slider .slick-list *").removeClass("event__image-anim");
        $(".profile__slider .slick-list *").removeClass("event__options-anim");
        $(".profile__slider .last-slide > img").attr(
            "src",
            $(".profile__slider .slick-current")
                .next()
                .next()
                .next()
                .find(".event__image > img")
                .attr("src")
        );
        $(".profile__slider .similiar-slider").slick("slickNext");
        if (
            $(".profile__slider .slick-current")
                .find(".event__image")
                .hasClass("event__image-anim")
        ) {
        } else {
            $(".profile__slider .slick-current")
                .next()
                .next()
                .find(".event__image")
                .addClass("event__image-anim");
            $(".profile__slider .slick-current")
                .next()
                .next()
                .find(".event__options")
                .addClass("event__options-anim");
        }
    });

    $("body").delegate(
        ".profile__slider .last-slide",
        "mouseenter",
        function () {
            $(".profile__slider .slick-list *").removeClass(
                "event__image-anim"
            );
            $(".profile__slider .last-slide > img").attr(
                "src",
                $(".profile__slider .slick-current")
                    .next()
                    .next()
                    .next()
                    .find(".event__image > img")
                    .attr("src")
            );
            $(".profile__slider .similiar-slider").slick("slickNext");
            if (
                $(".profile__slider .slick-current")
                    .find(".event__image")
                    .hasClass("event__image-anim")
            ) {
            } else {
                $(".profile__slider .slick-current")
                    .next()
                    .next()
                    .find(".event__image")
                    .addClass("event__image-anim");
                $(".profile__slider .slick-current")
                    .next()
                    .next()
                    .find(".event__options")
                    .addClass("event__options-anim");
            }
        }
    );

    $(".nearevents__slider .first-slide > img").attr(
        "src",
        $(".nearevents__slider .slick-current")
            .prev()
            .find(".event__image > img")
            .attr("src")
    );

    $("body").delegate(
        ".nearevents__slider .first-slide",
        "click",
        function () {
            $(".nearevents__slider .slick-list *").removeClass(
                "event__image-anim"
            );
            $(".nearevents__slider .slick-list *").removeClass(
                "event__options-anim"
            );
            $(".nearevents__slider .first-slide > img").attr(
                "src",
                $(".nearevents__slider .slick-current")
                    .prev()
                    .prev()
                    .find(".event__image > img")
                    .attr("src")
            );
            $(".nearevents__slider .similiar-slider").slick("slickPrev");
            if (
                $(".nearevents__slider .slick-current")
                    .find(".event__image")
                    .hasClass("event__image-anim")
            ) {
            } else {
                $(".nearevents__slider .slick-current")
                    .find(".event__image")
                    .addClass("event__image-anim");
                $(".nearevents__slider .slick-current")
                    .find(".event__options")
                    .addClass("event__options-anim");
            }
        }
    );

    $("body").delegate(
        ".nearevents__slider .first-slide",
        "mouseenter",
        function () {
            $(".nearevents__slider .slick-list *").removeClass(
                "event__image-anim"
            );
            $(".nearevents__slider .first-slide > img").attr(
                "src",
                $(".nearevents__slider .slick-current")
                    .prev()
                    .prev()
                    .find(".event__image > img")
                    .attr("src")
            );
            $(".nearevents__slider .similiar-slider").slick("slickPrev");
            if (
                $(".nearevents__slider .slick-current")
                    .find(".event__image")
                    .hasClass("event__image-anim")
            ) {
            } else {
                $(".nearevents__slider .slick-current")
                    .find(".event__image")
                    .addClass("event__image-anim");
                $(".nearevents__slider .slick-current")
                    .find(".event__options")
                    .addClass("event__options-anim");
            }
        }
    );

    /*
        slider welcome page
        */

    $(".related__slider .slider__prev").on("click", function () {
        $(".related__slider .similiar-slider").slick("slickPrev");
    });
    $(".related__slider .slider__next").on("click", function () {
        $(".related__slider .similiar-slider").slick("slickNext");
    });

    $(".noauth__slider .slider__prev").on("click", function () {
        $(".noauth__slider .similiar-slider").slick("slickPrev");
    });
    $(".noauth__slider .slider__next").on("click", function () {
        $(".noauth__slider .similiar-slider").slick("slickNext");
    });

    $(".nearevents__slider .slider__prev").on("click", function () {
        $(".nearevents__slider .similiar-slider").slick("slickPrev");
    });
    $(".nearevents__slider .slider__next").on("click", function () {
        $(".nearevents__slider .similiar-slider").slick("slickNext");
    });

    $(".profile__slider .slider__prev").on("click", function () {
        $(".profile__slider .similiar-slider").slick("slickPrev");
    });
    $(".profile__slider .slider__next").on("click", function () {
        $(".profile__slider .similiar-slider").slick("slickNext");
    });

    $(".slider__prev").on("click", function () {
        $(".similiar-genres,.nearby-events").slick("slickPrev");
    });

    $(".slider__next").on("click", function () {
        $(".similiar-genres,.nearby-events").slick("slickNext");
    });

    $(".slider-platform__prev").on("click", function () {
        $(".popular-platforms").slick("slickPrev");
    });

    $(".slider-platform__next").on("click", function () {
        $(".popular-platforms").slick("slickNext");
    });

    $(".who-goes__left").on("click", function () {
        $(".gonna-slider").slick("slickPrev");
    });

    $(".who-goes__right").on("click", function () {
        $(".gonna-slider").slick("slickNext");
    });
    $(".similiar-events__prev").on("click", function () {
        $(".similiar-event").slick("slickPrev");
    });
    $(".similiar-events__next").on("click", function () {
        $(".similiar-event").slick("slickNext");
    });
    $(".event-page-past__years-left").on("click", function () {
        $(".event-page-past__years-slider").slick("slickPrev");
    });
    $(".event-page-past__years-right").on("click", function () {
        $(".event-page-past__years-slider").slick("slickNext");
    });

    $("#gender").select2({
        minimumResultsForSearch: Infinity,
        width: 125,
    });
    $("#suggested-genre").select2({
        minimumResultsForSearch: Infinity,
        width: 180,
    });
    $("#suggested-my-genre").select2({
        minimumResultsForSearch: Infinity,
        width: 180,
    });

    var qsParams = queryStringDecode();

    var visitorAgeRangeHolder = [
        {
            from: qsParams.visitor_age_from || 16,
            to: qsParams.visitor_age_to || 70,
        },
    ];

    $("body").delegate(".signin__link, .signin__noauth", "click", function () {
        // window.scrollTo(0, 0);
        $(".signin__form").addClass("signin__form_show");
        $(".signin__form").css({ "border-spacing": "0px" });
        $(".signin__form").animate(
            { "border-spacing": "1px" },
            {
                step: function (now, fx) {
                    var x = -1 + 5 * now;
                    var angle = (Math.sin(x * Math.PI) / (x * Math.PI)) * 6;
                    $(this).css("transform", "rotate(" + angle + "deg)");
                },
                duration: 300,
                easing: "linear",
            }
        );
    });

    $(document).mouseup(function (e) {
        if (
            !$(".signin__form").is(e.target) &&
            $(".signin__form").has(e.target).length === 0 &&
            $(".signin__form").is(":visible")
        ) {
            $(".signin__form").removeClass("signin__form_show");
        }
    });
    $(document).mouseup((e) => {
        // Ignore the menu toggle button in this handler
        if ($(".burger-menu-btn").is(e.target)) {
            return;
        }
        // Click anywhere outside the menu closes it
        if (
            !$(".header__menu-mobile").is(e.target) &&
            $(".header__menu-mobile").has(e.target).length === 0 &&
            $(".header__menu-mobile").is(":visible")
        ) {
            $(".header__menu-mobile").hide();
        }
    });

    $(".event-page__map").magnificPopup({
        disableOn: 700,
        type: "iframe",
        mainClass: "mfp-fade",
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false,
    });

    $(".filters__track").click(function () {
        if ($(this).hasClass("filters__track_yes")) {
            $(this).removeClass("filters__track_yes");
        } else {
            $(this).addClass("filters__track_yes");
        }
    });
    if (qsParams.visitor_lfc == "true") {
        $(".filters__track").addClass("filters__track_yes");
    }

    $(".filters__track-friend").click(function () {
        if ($(this).hasClass("filters__track-friend_yes")) {
            $(this).removeClass("filters__track-friend_yes");
        } else {
            $(this).addClass("filters__track-friend_yes");
        }
    });
    if (qsParams.visitor_friend == "true") {
        $(".filters__track-friend").addClass("filters__track-friend_yes");
    }

    $(".filters__select")
        .val(qsParams.visitor_gender || "any")
        .change();

    $(".buttons-other__span").click(function () {
        if ($(this).hasClass("buttons-other__span_go")) {
            $(this).removeClass("buttons-other__span_go");
        } else {
            $(this).addClass("buttons-other__span_go");
        }
    });

    /*
        tooltip for
            ticket-state__noinfo
        */

    $("body").delegate(".btn_no-info", "mouseenter", function () {
        if (
            $(this)
                .parent()
                .parent()
                .find(".ticket-state__noinfo")
                .hasClass("show")
        ) {
            $(this)
                .parent()
                .parent()
                .find(".ticket-state__noinfo")
                .removeClass("show");
        } else {
            $(this)
                .parent()
                .parent()
                .find(".ticket-state__noinfo")
                .addClass("show");
        }
    });

    $("body").delegate(".btn_no-info", "mouseleave", function () {
        if (
            $(this)
                .parent()
                .parent()
                .find(".ticket-state__noinfo")
                .hasClass("show")
        ) {
            $(this)
                .parent()
                .parent()
                .find(".ticket-state__noinfo")
                .removeClass("show");
        } else {
            $(this)
                .parent()
                .parent()
                .find(".ticket-state__noinfo")
                .addClass("show");
        }
    });

    /*
        modal for
            ticket-state__info
        */

    $("body").delegate(".btn_ticket__has_modal", "click", function () {
        if (
            $(this)
                .parent()
                .parent()
                .find(".ticket-state__info")
                .hasClass("show")
        ) {
            $(this)
                .parent()
                .parent()
                .find(".ticket-state__info")
                .removeClass("show");
        } else {
            $(this)
                .parent()
                .parent()
                .find(".ticket-state__info")
                .addClass("show");
        }
    });

    /*
        close modal via click on modal__close, esc or over el
        */

    $(document).keyup(function (e) {
        if (e.which === 27) {
            $(".ticket-state__modal").removeClass("show");
        }
    });
    $(document).mouseup(function (e) {
        var div = $(".ticket-state__modal");
        var div_btn = $(".ticket-state__modal__close");
        if (
            !div_btn.is(e.target) &&
            !div.is(e.target) &&
            div.has(e.target).length === 0 &&
            div_btn.has(e.target).length === 0
        ) {
            const countToClose = $(".ticket-state__modal.show").length;
            div.removeClass("show");
            if (countToClose) {
                e.preventDefault();
                e.stopPropagation();
            }
        }
    });
    $("body").delegate(".ticket-state__modal__close", "click", function () {
        $(".ticket-state__modal").removeClass("show");
    });

    $(".ticket-state__invite-icon").click(function () {
        if (!$(this).hasClass("ticket-state__invite-icon_go")) {
            $(this)
                .parent()
                .parent()
                .find(".ticket-state__invite-hidden")
                .addClass("show");
        }
    });

    $(".ticket-state__link_red").click(function () {
        $(this)
            .parent()
            .parent()
            .parent()
            .find(".ticket-state__invite-icon")
            .addClass("ticket-state__invite-icon_go");
        $(this).parent().parent().removeClass("show");
    });

    $(".ticket-state__link").click(function () {
        $(this).parent().parent().removeClass("show");
    });

    /*
        tooltip gonnago-event
        */

    $("body").delegate(".gonnago-event", "mouseenter", function () {
        if ($(this).find(".gonnago-event__info").hasClass("show")) {
            $(this).find(".gonnago-event__info").removeClass("show");
        } else {
            $(this).find(".gonnago-event__info").addClass("show");
        }
    });

    $("body").delegate(".gonnago-event", "mouseleave", function () {
        if ($(this).find(".gonnago-event__info").hasClass("show")) {
            $(this).find(".gonnago-event__info").removeClass("show");
        } else {
            $(this).find(".gonnago-event__info").addClass("show");
        }
    });

    // Фильтры для пользователей идущих на эвент
    // Переделал на ajax

    $(".filters__button, .filters__confirm").click(function () {
        if ($(".filters__block").hasClass("show")) {
            $(".filters__block").removeClass("show");
        } else {
            $(".filters__block").addClass("show");
        }
    });

    // TODO: .filters__cancel тоже переделать на ajax

    $(".filters__cancel").click(function () {
        $(this).parent().parent().removeClass("show");
        $(this)
            .parent()
            .parent()
            .parent()
            .find(".filters__button")
            .css("box-shadow", "0 0 5px 1px #e94251");

        var age = visitorAgeRangeHolder[0];
        var slug = window.location.href.split("/").reverse()[1];
        $.ajax({
            url: "/event/gonnago/",
            method: "GET",
            data: {
                slug: slug,
                visitor_age_from: "",
                visitor_age_to: "",
                visitor_gender: "",
                visitor_lfc: "",
                visitor_friend: "",
            },
            success: function success(data) {
                $(".gonna-slider").html(data.html);
                document.location.href = window.location.href.split("?")[0];
            },
        });
    });

    $(".filters__confirm").click(function () {
        var age = visitorAgeRangeHolder[0];
        var slug = window.location.href.split("/").reverse()[1];
        $.ajax({
            url: "/event/gonnago/",
            method: "GET",
            data: {
                slug: slug,
                visitor_age_from: age.from,
                visitor_age_to: age.to,
                visitor_gender: $(".filters__select").val(),
                visitor_lfc:
                    $(".filters__track").hasClass("filters__track_yes"),
                visitor_friend: $(".filters__track-friend").hasClass(
                    "filters__track-friend_yes"
                ),
            },
            success: function success(data) {
                $(".gonna-slider").slick("unslick");
                $(".gonna-slider .gonna-slider__block").remove();
                $(".gonna-slider").html(data.html);
                $(".who-goes__title").html(data.title);

                $(".gonna-slider").slick({
                    TouchMove: true,
                    touchThreshold: 300,
                    accessibility: false,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    responsive: [
                        {
                            breakpoint: 1190,
                            settings: {
                                slidesToShow: 5,
                            },
                        },
                        {
                            breakpoint: 990,
                            settings: {
                                slidesToShow: 3,
                            },
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 3,
                            },
                        },
                        {
                            breakpoint: 575,
                            settings: {
                                slidesToShow: 3,
                            },
                        },
                    ],
                });
            },
        });
    });

    a = 0;
    $(".popular-bands__link").click(function () {
        if (a == 0) {
            $("#popular-bands-hidden").css("display", "flex");
            $(".popular-bands__link").html(
                'Hide bands <i class="fa fa-angle-up" aria-hidden="true"></i>'
            );
            $(".popular-bands__link").css("color", "#565656");
            a = 1;
        } else {
            $("#popular-bands-hidden").css("display", "none");
            $(".popular-bands__link").html(
                'See all bands <i class="fa fa-long-arrow-right" aria-hidden="true"></i>'
            );
            $(".popular-bands__link").css("color", "#e94251");
            a = 0;
        }
    });
    $(".info__title_year").each(function () {
        if ($(this).html() != "") {
            $(this).parent().addClass("info__title_with-year");
        }
    });
    function hideShortLineups() {
        $(".info__lineup").each(function () {
            if (this.offsetWidth > 0 && this.offsetWidth >= this.scrollWidth) {
                $(this)
                    .parent()
                    .parent()
                    .find(".info__open-lineup")
                    .css("display", "none");
            }
        });
    }
    hideShortLineups();

    $(".user-nav__link").click(function () {
        $(".user-nav__item").each(function () {
            $(this).removeClass("active");
        });
        $(".user-nav__link").each(function () {
            $(this).removeClass("active");
        });
        $(this).parent().addClass("active");
        $(this).addClass("active");
    });
    $(".scalable-textarea").on("DOMSubtreeModified", function (event) {
        $(this).parent().find("input").val($(this).text());
    });

    $(".logged").click(function (e) {
        if ($(e.target).attr("id") == "toProfile") {
            return true;
        }
        if ($(this).parent().find(".user").hasClass("show")) {
            $(this).parent().find(".user").removeClass("show");
        } else {
            $(this).parent().find(".user").addClass("show");
        }
    });

    $("body").delegate(".more__button", "click", function () {
        if ($(this).parent().find(".more__block").hasClass("show")) {
            $(this).parent().find(".more__block").removeClass("show");
            $(this).parent().css("box-shadow", "none");
        } else {
            $(this).parent().find(".more__block").addClass("show");
            $(this).parent().css("box-shadow", "0 0 15px 2px rgba(0,0,0,.2)");
        }
    });

    $(".friends-nav__list-link").click(function () {
        $(this)
            .parent()
            .parent()
            .find(".friends-nav__link")
            .html($(this).html());
        if ($(this).parent().hasClass("show")) {
            $(this).parent().removeClass("show");
        } else {
            $(this).parent().addClass("show");
        }
    });
    $(".friends-nav__link").click(function () {
        /*
        $('.friends-nav__link').each(function () {
            $(this).removeClass('active');
        });
        $(this).addClass('active');
        */
        $(".friends-nav__links").removeClass("show");
        if ($(this).parent().find(".friends-nav__links").hasClass("show")) {
            $(this).parent().find(".friends-nav__links").removeClass("show");
        } else {
            $(this).parent().find(".friends-nav__links").addClass("show");
        }
    });
    $(".tags__description").click(function () {
        if ($(this).parent().find(".tags__hidden").hasClass("show")) {
            $(this).parent().find(".tags__hidden").removeClass("show");
            $(this).parent().css("box-shadow", "none");
            $(this).css("box-shadow", "none");
        } else {
            $(this).parent().find(".tags__hidden").addClass("show");
            $(this).parent().css("box-shadow", "0 0 15px 2px rgba(0,0,0,.2)");
            $(this).css("box-shadow", "none");
        }
    });
    $(".sort__selected").click(function () {
        if ($(this).parent().find(".sort__hidden").hasClass("show")) {
            $(this).parent().find(".sort__hidden").removeClass("show");
        } else {
            $(this).parent().find(".sort__hidden").addClass("show");
        }
    });
    $(".sort__select").click(function () {
        var sort = $(this).html();
        $(this).html($(this).parent().parent().find(".sort__selected").html());
        $(this).parent().parent().find(".sort__selected").html(sort);
        $(".sort__selected").parent().find(".sort__hidden").removeClass("show");
    });

    $(".header__mobile-menu").click(function () {
        if ($(".header__middle-section").is(":visible")) {
            $(".header__middle-section").css("display", "none");
        } else {
            $(".header__middle-section").css("display", "flex");
        }
    });
    $(".comments__minus, .comments__plus").click(function () {
        $(this).parent().find(".comments__minus").removeClass("click");
        $(this).parent().find(".comments__plus").removeClass("click");
        $(this).addClass("click");
    });
    $(".timetable__button").click(function () {
        if ($(this).hasClass("active")) {
            $(this).removeClass("active");
        } else {
            $(this).addClass("active");
        }
    });
    $(".timetable__link").click(function () {
        if ($(this).hasClass("click")) {
            $(".timetable__link").removeClass("click");
        } else {
            $(".timetable__link").addClass("click");
        }
    });
    $(".my-menu-button").click(function () {
        $(".my-menu").toggle(500, function () {
            if ($(this).is(":visible")) {
                $(this).css("display", "flex");
                $(".my-menu-button").css({
                    background: "#fff",
                    color: "#ec0c21",
                    "box-shadow": "none",
                });
                $(".my-menu-button").html(
                    localized("Close menu") +
                        '<i class="fa fa-angle-up" aria-hidden="true"></i>'
                );
            } else {
                $(this).css("display", "none");
                $(".my-menu-button").css({
                    background: "#ec0c21",
                    color: "#fff",
                    "box-shadow": "0 0 5px 2px rgba(0, 0, 0, 0.4)",
                });
                $(".my-menu-button").html(
                    localized("Open menu") +
                        '<i class="fa fa-angle-down" aria-hidden="true"></i>'
                );
            }
        });
    });

    $(".geolocation__cancel").click(function () {
        $(this).parent().parent().removeClass("show");
    });
    eventHeight = 0;
    $(".event").each(function () {
        if ($(this).height() > eventHeight) {
            eventHeight = $(this).height();
        }
        $(this).height(eventHeight);
    });
    $(".footer__lang-link").click(function () {
        $(".footer__lang-link").each(function () {
            $(this).removeClass("current");
        });
        $(this).addClass("current");
    });

    $(".location-select input").autocomplete({
        source: document.location.origin + "/user/getcity/",
        minLength: 2,
        delay: 500,
    });

    $(".distance-select button.apply").click(function () {
        const popupsCommonParent = $(this)
            .parents(".area-select-popup")
            .parent();

        $.get(
            document.location.origin +
                "/user/setcity/?city=" +
                popupsCommonParent.find(".location-select input").val() +
                "&distance=" +
                popupsCommonParent.find(".distance-select select").val()
        ).done(function () {
            location.reload();
        });
        return false;
    });

    $(".location-select button.apply").click(function () {
        const popupsCommonParent = $(this)
            .parents(".area-select-popup")
            .parent();

        $.get(
            document.location.origin +
                "/user/setcity/?city=" +
                popupsCommonParent.find(".location-select input").val() +
                "&distance=" +
                popupsCommonParent.find(".distance-select select").val()
        ).done(function () {
            location.reload();
        });
        return false;
    });

    $(".area-select-popup button.cancel").click(function () {
        $(this).parents(".area-select-popup").removeClass("show");
    });

    $("#login_form_id .submit").click(function (e) {
        $.ajax({
            type: "POST",
            url: "/api/login/",
            data: $("#login_form_id").serialize(),
            success: function (response) {
                if (response.success) {
                    window.location.reload();
                } else {
                    $("#login_form_id .error").text("");
                    for (var key in response.errors) {
                        if (response.errors.hasOwnProperty(key)) {
                            $("#login_form_id ." + key + "_error").text(
                                response.errors[key]
                            );
                        }
                    }
                }
            },
        });
    });

    $("#signup_form_id .submit").click(function (e) {
        $.ajax({
            type: "POST",
            url: "/api/signup/",
            data: $("#signup_form_id").serialize(),
            success: function (response) {
                if (response.success) {
                    window.location.href = "/signup-code-sent/";
                } else {
                    $("#signup_form_id .error").text("");
                    for (var key in response.errors) {
                        if (response.errors.hasOwnProperty(key)) {
                            $("#signup_form_id ." + key + "_error").text(
                                response.errors[key]
                            );
                        }
                    }
                }
            },
        });
    });

    $(".thumbnail-image").magnificPopup({
        type: "image",
        callbacks: {
            elementParse: function (item) {
                item.src = item.el.attr("data-full-img-url");
            },
        },
    });

    $(".location-select-by-geolocation").click(function (e) {
        e.preventDefault();

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                successFunction,
                errorFunction
            );
        }

        //Get the latitude and the longitude;
        function successFunction(position) {
            var lat = position.coords.latitude;
            var lng = position.coords.longitude;
            $.get(
                document.location.origin +
                    "/user/setcitybycoords/?lat=" +
                    lat +
                    "&lng=" +
                    lng,
                function (data) {}
            ).done(function () {
                location.reload();
            });
        }

        function errorFunction() {
            alert("Geocoder failed");
        }
    });

    function set_ribbon_buttons() {
        $(".years_more-intend .years__left.clickable").click(function () {
            load_ribbon(ribbon_cur_year - 1);
        });

        $(".years_more-intend .years__right.clickable").click(function () {
            load_ribbon(ribbon_cur_year + 1);
        });
    }

    set_ribbon_buttons();

    function load_ribbon(year) {
        $.ajax({
            url:
                "/fest_years_ribbon/" +
                window.ribbon_fest_id +
                "/" +
                year +
                "/",
            method: "GET",
            success: function success(data) {
                $(".years_more-intend").replaceWith(data);
                set_ribbon_buttons();
            },
        });
    }

    $("#about-btn").click(function () {
        grecaptcha.ready(function () {
            grecaptcha
                .execute("6LeejNQdAAAAAJgTxir1MNMWNutH69cQN1GTKH2-", {
                    action: "submit",
                })
                .then(function (token) {
                    var formData =
                        $("#feedback-form").serialize() + "&recaptcha=" + token;
                    $.post("/feedback/", formData, function () {
                        window.location.href =
                            "/about-result/" + window.location.search;
                    });
                });
        });
    });

    $("#feedback-btn").click(function () {
        grecaptcha.ready(function () {
            grecaptcha
                .execute("6LeejNQdAAAAAJgTxir1MNMWNutH69cQN1GTKH2-", {
                    action: "submit",
                })
                .then(function (token) {
                    var formData =
                        $("#feedback-form").serialize() + "&recaptcha=" + token;
                    $.post("/feedback/", formData, function () {
                        window.location.href =
                            "/feedback-result/" + window.location.search;
                    });
                });
        });
    });

    $("#feedback-main-btn").click(function () {
        grecaptcha.ready(function () {
            grecaptcha
                .execute("6LeejNQdAAAAAJgTxir1MNMWNutH69cQN1GTKH2-", {
                    action: "submit",
                })
                .then(function (token) {
                    var formData =
                        $("#feedback-form").serialize() + "&recaptcha=" + token;
                    $.post("/feedback_main/", formData, function () {
                        window.location.href =
                            "/feedback-result/" + window.location.search;
                    });
                });
        });
    });

    $(".merge-events").click(function () {
        if (confirm("Уверен?")) {
            $(".merge-events").attr("disabled", true);
            $.post(
                "/admin-merge-events/",
                { from_id: $(this).data("from"), to_id: $(this).data("to") },
                function () {
                    window.location.reload();
                }
            ).fail(function (data) {
                document.write("<pre>" + data.responseText + "</pre>");
            });
        }
    });

    $(".run-script").click(function () {
        if (confirm("Уверен?")) {
            $(".merge-events").attr("disabled", true);
            $.post("/admin-scripts/", { name: $(this).text() }, function () {
                window.location.reload();
            }).fail(function (data) {
                document.write("<pre>" + data.responseText + "</pre>");
            });
        }
    });

    window.datePickerToIso = function (jqueryDatepicker) {
        // https://stackoverflow.com/a/27673433/63227
        var date = jqueryDatepicker.datepicker("getDate");
        return $.datepicker.formatDate("yy-mm-dd", date);
    };

    // all_chats
    $("button#send_chat_message").click(function () {
        $.ajax({
            type: "POST",
            url: "/send_message/",
            data: $("form#send_message").serialize(),
            success: function (response) {
                window.location.reload();
            },
        });
    });

    // profile/mymessages/
    $("a#send_chat_message").click(function () {
        $.ajax({
            type: "POST",
            url: "/send_message/",
            data: $("form#send_message").serialize(),
            success: function (response) {
                loadChat($("#chat_id").val());
            },
        });
    });

    $(".message-part").click(function () {
        $(".message-chat").css("display", "none");
        $(this).next().toggle("slow");
    });

    window.chatsSize = 10;
    window.currentChatSize = 10;

    function loadChats(chatId) {
        current_chat_size = 10;

        $("#chat_id").val(chatId);

        $.ajax({
            type: "GET",
            url: "/chats_json/",
            success: function (response) {
                if (response.has_more) {
                    $(".chat-more").removeClass("display-none");
                } else {
                    $(".chat-more").addClass("display-none");
                }
                $(".chat-row").remove();
                for (var i = 0; i < response.chats.length; i++) {
                    var chatNode = $(".chat-template").clone();
                    chatNode.removeClass("chat-template");
                    chatNode.addClass("chat-row");

                    var chat = response.chats[i];

                    chatNode.attr("data-chat-id", chat.id);
                    chatNode.attr(
                        "href",
                        "/profile/mymessages/chat/" + chat.id
                    );
                    chatNode.find(".chat-avatars").html(
                        chat.other_users_avatars
                            .map(function (url) {
                                return (
                                    '<img src="' +
                                    url +
                                    '" alt="" role="presentation"/>'
                                );
                            })
                            .join("")
                    );
                    chatNode.find(".chat-name").text(chat.name);
                    chatNode
                        .find(".chat-last-message-text")
                        .text(chat.last_message_text.slice(0, 110));
                    $(".chat-list").append(chatNode);
                }
            },
        });
    }

    // вся переписка
    function loadChat(chatId) {
        //current_chat_size = 10;

        $.ajax({
            type: "GET",
            //url: '/chat_json/' + chatId + '/?total_size='+window.currentChatSize,
            url: "/chat_json/" + chatId,
            data: $("form#send_message").serialize(),
            success: function (response) {
                if (response.messages.length == 0) {
                    $(".message-list").css("height", "38px");
                    $(".message-list").css("overflow-y", "unset");
                    $(".message-list").css("padding", "5px 10px");
                    if (window.location.host.startsWith == "ru.") {
                        $(".message-list").text(
                            "У вас нет сообщений в этом чате"
                        );
                    } else {
                        $(".message-list").text("This chat has not messages");
                    }
                }
                $(".chat-participants span.names").text(
                    response.participants.join(", ")
                );
                //if (response.has_more) {
                //    $('.message-more').removeClass('display-none');
                //} else {
                //    $('.message-more').addClass('display-none');
                //}
                $(".message-form").removeClass("display-none");
                $(".message-row").remove();
                for (var i = 0; i < response.messages.length; i++) {
                    $("form#send_message textarea").val("");
                    var msgNode = $(".message-template").clone();
                    msgNode.removeClass("message-template");
                    msgNode.addClass("message-row");
                    var message = response.messages[i];
                    if (message.author_id) {
                        msgNode
                            .find(".chat-avatars")
                            .html(
                                '<a href="/profile/id/' +
                                    message.author_id +
                                    '"><img src="' +
                                    message.author_avatar_url +
                                    '" alt="" role="presentation"/></a> '
                            );
                        msgNode.find(".message-author").html(message.author);
                    } else {
                        msgNode.find(".message-author").text(message.author);
                    }
                    Object.entries(response.messages[i]).forEach(function (v) {
                        if (v[0] == "received") {
                            if (Object.values(v[1])[0] == "✓") {
                                msgNode
                                    .find(".message-text")
                                    .addClass("message-text-strong")
                                    .text(response.messages[i].text);
                            } else {
                                msgNode
                                    .find(".message-text")
                                    .text(response.messages[i].text);
                            }
                        }
                    });
                    msgNode.find(".message-sent").text(message.date_sent);
                    msgNode.find(".message-received").text(
                        Object.keys(message.received).map(function (user) {
                            if (Object.keys(message.received).length > 1) {
                                return user + " " + message.received[user];
                            } else {
                                return message.received[user];
                            }
                        })
                    );
                    $(".message-list").append(msgNode);
                }
            },
        });
    }

    // только непрочитанные сообщения
    function loadChatUnread(chatId) {
        //current_chat_size = 10;

        $.ajax({
            type: "GET",
            //url: '/chat_json/' + chatId + '/?total_size='+window.currentChatSize,
            url: "/chat_json/" + chatId,
            data: $("form#send_message").serialize(),
            success: function (response) {
                $(".chat-participants span.names").text(
                    response.participants.join(", ")
                );
                //if (response.has_more) {
                //    $('.message-more').removeClass('display-none');
                //} else {
                //    $('.message-more').addClass('display-none');
                //}
                $(".message-form").removeClass("display-none");
                $(".message-row").remove();
                for (var i = 0; i < response.messages.length; i++) {
                    $("form#send_message textarea").val("");
                    Object.entries(response.messages[i]).forEach(function (v) {
                        if (v[0] == "received") {
                            if (Object.values(v[1])[0] == "✓") {
                                var msgNode = $(".message-template").clone();

                                msgNode.removeClass("message-template");
                                msgNode.addClass("message-row");

                                var message = response.messages[i];

                                if (message.author_id) {
                                    msgNode
                                        .find(".chat-avatars")
                                        .html(
                                            '<a href="/profile/id/' +
                                                message.author_id +
                                                '"><img src="' +
                                                message.author_avatar_url +
                                                '" alt="" role="presentation"/></a> '
                                        );
                                    msgNode
                                        .find(".message-author")
                                        .html(message.author);
                                } else {
                                    msgNode
                                        .find(".message-author")
                                        .text(message.author);
                                }

                                msgNode
                                    .find(".message-text")
                                    .addClass("message-text-strong")
                                    .text(response.messages[i].text);

                                msgNode
                                    .find(".message-sent")
                                    .text(message.date_sent);
                                msgNode.find(".message-received").text(
                                    Object.keys(message.received).map(function (
                                        user
                                    ) {
                                        if (
                                            Object.keys(message.received)
                                                .length > 1
                                        ) {
                                            return (
                                                user +
                                                " " +
                                                message.received[user]
                                            );
                                        } else {
                                            return message.received[user];
                                        }
                                    })
                                );

                                $(".message-list").append(msgNode);
                            } else {
                            }
                        }
                    });
                }
            },
        });
    }

    // загрузить все сообщения при загрузке страницы
    if ($("#chat_id").attr("value")) {
        loadChat($("#chat_id").attr("value"));
    }

    // загрузить все сообщения при клике на кнопку фильтра
    $(".chat__all-messages").click(function () {
        if ($("#chat_id").attr("value")) loadChat($("#chat_id").attr("value"));
    });

    // загрузить непрочитанные сообщения при клике на кнопку фильтра
    $(".chat__unread-messages").click(function () {
        if ($("#chat_id").attr("value"))
            loadChatUnread($("#chat_id").attr("value"));
    });

    $("a.message-more").click(function () {
        var chatId = $("#chat_id").val();
        window.currentChatSize += 10;
        loadChat(chatId);
    });

    $("a.chat-more").click(function () {
        window.chatsSize += 10;
        loadChats();
    });

    if ($(".chat-list").length) {
        // Первоначальная загрузка
        loadChats();
    }

    $(".send_message__button").click(function (e) {
        $("#send_message_from_pou").submit();
    });

    // send message from pou-page
    /*
    $('.send_message__button').click(function(e) {
        $.ajax({
            type: "GET",
            url: '/send_message_from_pou/',
            data: $('#send_message_from_pou').serialize(),
            success: function (response) {
                //console.log(response);
                target_user_id = response.target_user_id;
                request_user_id = response.request_user_id;
                chat_id = response.chat_id;
                window.location.href = '/profile/mymessages/?target_user_id='+target_user_id+'&request_user_id='+request_user_id+'&chat_id='+chat_id;
                //console.log('ajax запрос выполнен');
           }
        });
    });
    */

    // auth by enter on input

    $(".signin__input").keyup(function (e) {
        if (e.which === 13) {
            $(".signin__login").trigger("click");
        }
    });

    // resize textarea
    $(".message-form-textarea").on("input", function () {
        $(this).outerHeight(38).outerHeight(this.scrollHeight);
    });

    // resize message-list
    $(".message-list").css({
        height: $(window).height() - 525 + "px",
    });

    $(window).resize(function () {
        $(".message-list").css({ height: $(window).height() - 525 + "px" });
    });

    // lazyload images
    var imgs = document.querySelectorAll("img.lazyload[data-src]");
    //var i = $('img.lazyload[data-src]');
    //i.forEach(function(img) {
    Array.prototype.forEach.call(imgs, function () {
        img.setAttribute("src", img.getAttribute("data-src"));
        img.onload = function () {
            img.removeAttribute("data-src");
        };
    });

    $(".header .header__button").click(function () {
        if ($(window).width() >= 752) {
            $(".header__livestream-link").toggle();
        }
        if ($(window).width() > 575 && $(window).width() < 751) {
            $(".logged__lang-block").toggle();
            $(".header__livestream-link-mobile").toggle();
        }
    });

    // скрываем Календарь по клику не на него
    $(document).mouseup(function (e) {
        var div = $(".user");
        var div_button = $(".logged");
        if (
            !div.is(e.target) &&
            div.has(e.target).length === 0 &&
            !div_button.is(e.target) &&
            div_button.has(e.target).length === 0
        )
            div.parent().find(".user").removeClass("show");
    });

    // скрываем Календарь по ESC
    $(document).keyup(function (e) {
        if (e.which === 27) {
            var div = $(".user");
            var div_button = $(".logged");
            if (
                !div.is(e.target) &&
                div.has(e.target).length === 0 &&
                !div_button.is(e.target) &&
                div_button.has(e.target).length === 0
            )
                div.parent().find(".user").removeClass("show");
        }
    });

    // слайдер для мобильной версии дат в live_stream events
    $(".live-streams__calendar_dates__mobile").slick({
        TouchMove: true,
        touchThreshold: 300,
        accessibility: false,
        arrows: true,
        dots: false,
        draggable: true,
        slidesToShow: 13,
        slidesToScroll: 1,
        swipeToSlide: true,
        waitForAnimate: true,
        responsive: [
            {
                breakpoint: 1190,
                settings: {
                    slidesToShow: 16,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 13,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 525,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 3,
                },
            },
        ],
    });

    if ($(".grid").masonry) {
        $(".grid").masonry({
            itemSelector: ".grid-item",
            columnWidth: 120,
        });
    }

    // lazyload tickets
    // $(window).scroll(function() {
    //     if ($("#show-more-tickets").is(":visible")){
    //         if($(window).scrollTop() + $(window).height() >= $("#show-more-tickets").offset().top) {
    //             if (!window.tickedAutoloadTriggered) {
    //                 window.tickedAutoloadTriggered = true;
    //                 $("#show-more-tickets").trigger("click");
    //                 window.tickedAutoloadTriggered = false;
    //             }
    //         }
    //     }
    // });

    // удаление дат из фильтра и перезагрузка страницы
    $(".filter-calendar__date-clear").click(function (el) {
        $("#datepicker-to, #datepicker-from").val("");
        Storage.update({
            type: "get",
            startDate: isoDate(new Date()),
            endDate: "upcm",
            page: 1,
            order: "straight",
            offset: 0,
        });
        GetTickets(Storage.getData());
    });

    $("body").delegate(".tickets-dropdown-button", "click", function (event) {
        const menu = $(this)
            .parents(".ticket-group")
            .find(".tickets-dropdown-menu");
        if (menu.hasClass("show")) {
            menu.removeClass("show");
        } else {
            menu.addClass("show");
        }
        event.preventDefault();
        event.stopPropagation();
    });

    // filter buttons noauth click
    $("body").delegate(
        ".timetable__button_koza_noauth, .timetable__button_bands_noauth",
        "click",
        function () {
            if ($(".signin__form").hasClass("signin__form_show")) {
                $(".signin__form").removeClass("signin__form_show");
            } else {
                $(".signin__form").addClass("signin__form_show");
            }
        }
    );

    $(".timetable__button_cancelled").click(function () {
        if (
            $(this)
                .find(".hide-cancelled__switch")
                .hasClass("hide-cancelled__switch_yes")
        ) {
            $(this)
                .find(".hide-cancelled__switch")
                .removeClass("hide-cancelled__switch_yes");
        } else {
            $(this)
                .find(".hide-cancelled__switch")
                .addClass("hide-cancelled__switch_yes");
        }
    });

    $(".timetable-filter__button").click(function () {
        $(".filters-container").slideToggle("slow");
        $(".timetable_by_style").css("display", "flex");
    });

    $(".signin__registration, .joinus__noauth, .join-block-btn").click(
        function (e) {
            e.preventDefault();
            var id = $("#myModal");
            var winH = $(window).height();
            var winW = $(window).width();
            id.removeClass("fade");
            id.css("display", "block");
            id.css("top", winH / 2 - id.height() / 2);
            id.css("left", winW / 2 - id.width() / 2);
            id.fadeIn(500);
        }
    );
    $("button.close").click(function (e) {
        e.preventDefault();
        var id = $("#myModal");
        id.fadeOut(500);
        id.css("display", "none");
    });

    // hide lineup-hidden show on click outside of the pop-up menu
    $(document).mouseup(function (e) {
        var div = $(".lineup-hidden");
        var div_btn = $(".info__open-lineup");
        if (
            !div_btn.is(e.target) &&
            !div.is(e.target) &&
            div.has(e.target).length === 0 &&
            div_btn.has(e.target).length === 0
        ) {
            div_btn.removeClass("info__open-lineup-inversed");
            div.removeClass("show");
            e.stopPropagation();
            e.preventDefault();
        }
    });

    $(".manage-tabs-names__tab-name").click(function (e) {
        //e.preventDefault();

        var tab_name = $(this).data("tab-name");
        window.location.hash = tab_name;

        var target = decodeURIComponent(window.location.hash),
            $target = $(target);
        var to_top = $target.offset().top - 130;
        $("html, body").scrollTop(to_top);

        $(".manage-tabs-names .manage-tabs-names__tab-name").removeClass(
            "manage-tabs-names__tab-name__tab-active"
        );
        $(this).addClass("manage-tabs-names__tab-name__tab-active");
        $(".manage-tabs-content .manage-tabs-content__tab-content").css(
            "display",
            "none"
        );
        $(".manage-tabs-content__tab-content").each(function (el) {
            if ($(this).data("tab-content-name") == tab_name) {
                $(this).css("display", "block");
            }
        });
    });

    let hash = window.location.hash.replace(/#/, "");
    if (hash) {
        $(".manage-tabs-content .manage-tabs-content__tab-content").css(
            "display",
            "none"
        );
        $(".manage-tabs-content__tab-content").each(function (el) {
            if ($(this).data("tab-content-name") === hash) {
                $(this).css("display", "block");
            }
        });
    }

    // Dynamically add new forms to artist/event mgmt pages
    // From https://medium.com/all-about-django/adding-forms-dynamically-to-a-django-formset-375f1090c2b0
    function updateElementIndex(el, prefix, ndx) {
        var id_regex = new RegExp("(" + prefix + "-\\d+)");
        var replacement = prefix + "-" + ndx;
        if ($(el).attr("for")) {
            $(el).attr("for", $(el).attr("for").replace(id_regex, replacement));
        }
        if (el.id) {
            el.id = el.id.replace(id_regex, replacement);
        }
        if (el.name) {
            el.name = el.name.replace(id_regex, replacement);
        }
    }
    function cloneMore(selector, prefix) {
        var newElement = $(selector).clone(true);
        var total = $("#id_" + prefix + "-TOTAL_FORMS").val();
        newElement
            .find(
                ":input:not([type=button]):not([type=submit]):not([type=reset])"
            )
            .each(function () {
                var name = $(this)
                    .attr("name")
                    .replace("-" + (total - 1) + "-", "-" + total + "-");
                var id = "id_" + name;
                if (
                    $(this).attr("type") !== "hidden" ||
                    $(this).attr("name").endsWith("-id")
                ) {
                    $(this).val("").removeAttr("checked");
                }
                $(this).attr({ name: name, id: id });
            });
        newElement.find("label").each(function () {
            var forValue = $(this).attr("for");
            if (forValue) {
                forValue = forValue.replace(
                    "-" + (total - 1) + "-",
                    "-" + total + "-"
                );
                $(this).attr({ for: forValue });
            }
        });
        total++;
        $("#id_" + prefix + "-TOTAL_FORMS").val(total);
        $(selector).after(newElement);
        var conditionRow = $(".form-row:not(:last)");
        conditionRow
            .find(".btn.add-form-row")
            .removeClass("btn-success")
            .addClass("btn-danger")
            .removeClass("add-form-row")
            .addClass("remove-form-row")
            .html(
                '<span class="glyphicon glyphicon-minus" aria-hidden="true"></span>'
            );
        return false;
    }
    function deleteForm(selector, prefix, btn) {
        var total = parseInt($("#id_" + prefix + "-TOTAL_FORMS").val());
        if (total > 1) {
            btn.closest(selector).remove();
            var forms = $(selector);
            $("#id_" + prefix + "-TOTAL_FORMS").val(forms.length);
            for (var i = 0, formCount = forms.length; i < formCount; i++) {
                $(forms.get(i))
                    .find(":input")
                    .each(function () {
                        updateElementIndex(this, prefix, i);
                    });
            }
        }
        return false;
    }
    $(document).on("click", ".add-form-row", function (e) {
        e.preventDefault();
        var formType = $(this).attr("data-formtype");
        cloneMore(".formtype_" + formType + ":last", formType);
        return false;
    });
    $(document).on("click", ".remove-form-row", function (e) {
        e.preventDefault();
        var formType = $(this).attr("data-formtype");
        deleteForm(".formtype_" + formType + ":last", formType, $(this));
        return false;
    });

    $(".settings__socialnetwork__info__close").click(function () {
        $.ajax({
            async: true,
            type: "POST",
            url: "/close_hint/",
            data: {
                hint_name: "connect_vk",
            },
            success: function success(data) {
                $(".settings__socialnetwork__info").hide();
            },
        });
    });

    $("#eye_password").click(() => {
        const passwordInput = $("#id_password");
        const typ =
            passwordInput.attr("type") === "password" ? "text" : "password";
        passwordInput.attr("type", typ);
    });

    // ссылка на открытую дату в поле to:
    // https://stackoverflow.com/a/8739847/4008170

    $("#datepicker-map-from").datepicker({
        ...window.calendarLangParams,
        dateFormat: "yy-mm-dd",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: "",
        beforeShowDay: function beforeShowDay(date) {
            var now = new Date();
            var classes = "";
            if (date < now) classes += "dayInThePast ";
            return [true, classes];
        },
    });
    $("#datepicker-map-to").datepicker({
        ...window.calendarLangParams,
        dateFormat: "yy-mm-dd",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: "",
        beforeShow: function () {
            setTimeout(() => {
                $("#ui-datepicker-div").append(
                    `<div class="toDateInFarFuture"><span>${localized(
                        "All future events"
                    )}</span></div>`
                );

                $(".toDateInFarFuture span").click(function () {
                    $("#datepicker-map-to")
                        .datepicker("setDate", "2100-01-01")
                        .trigger("change");
                });
            }, 0);
        },
        beforeShowDay: function beforeShowDay(date) {
            var now = new Date();
            return [true, date < now ? "dayInThePast " : ""];
        },
    });

    // param=date-from, param=date-from

    // Tue Nov 26 2019 00:00:00 GMT+0200 (Восточная Европа, стандартное время)
    // 26.11.2019

    $("#datepicker-map-from").change(function () {
        var path = window.location.pathname;
        var param = window.location.search
            .replace("?", "")
            .split("&")[0]
            .split("=")[1];
        var param_mode = window.location.search
            .replace("?", "")
            .split("&")[0]
            .split("=")[0];

        var delimiter = "?";

        // в поле from нельзя выбрать дату раньше сегодняшнего дня

        var d = new Date();

        var current_date =
            d.getFullYear() +
            "-" +
            (d.getMonth() + 1 < 10
                ? "0" + (d.getMonth() + 1)
                : d.getMonth() + 1) +
            "-" +
            (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        var dtpkr_from = $(this).datepicker("getDate");
        var from_date =
            dtpkr_from.getFullYear() +
            "-" +
            (dtpkr_from.getMonth() + 1 < 10
                ? "0" + (dtpkr_from.getMonth() + 1)
                : dtpkr_from.getMonth() + 1) +
            "-" +
            (dtpkr_from.getDate() < 10
                ? "0" + dtpkr_from.getDate()
                : dtpkr_from.getDate());

        // if not in get(to_date)
        //var dtpkr_to = $("#datepicker-map-to").datepicker('getDate');
        //var to_date = dtpkr_to.getFullYear() + "-" + (dtpkr_to.getMonth() + 1) + "-" + ( dtpkr_to.getDate() < 10 ? ('0' + dtpkr_to.getDate()) : dtpkr_to.getDate() );

        // if in get(to_date)
        var to_date = $("#datepicker-map-to").val();

        if (from_date < current_date) {
            from_date = current_date;
            $(this).val(from_date);
        }

        if (from_date > to_date && to_date != "") {
            to_date = from_date;
            $("#datepicker-map-to").val(to_date);
        } else {
            // брать значение при клике на 'открытая дата', фильтр, без ограничения по дате
            //to_date = '2100-12-31';
            //$("#datepicker-map-to").val(to_date);
        }

        if ($("#datepicker-map-to").val()) {
            if (
                $("#datepicker-map-to").datepicker("getDate") >
                $(this).datepicker("getDate")
            ) {
                if (param_mode == "mode") {
                    window.location =
                        path +
                        delimiter +
                        "mode=" +
                        param +
                        "&date-from=" +
                        from_date +
                        "&date-to=" +
                        to_date;
                } else {
                    window.location =
                        path +
                        delimiter +
                        "date-from=" +
                        from_date +
                        "&date-to=" +
                        to_date;
                }
            } else {
                if (param_mode == "mode") {
                    window.location =
                        path +
                        delimiter +
                        "mode=" +
                        param +
                        "&date-from=" +
                        from_date +
                        "&date-to=" +
                        to_date;
                } else {
                    window.location =
                        path +
                        delimiter +
                        "date-from=" +
                        from_date +
                        "&date-to=" +
                        to_date;
                }
            }
        }
    });

    $("#datepicker-map-to").change(function () {
        var path = window.location.pathname;
        var param = window.location.search
            .replace("?", "")
            .split("&")[0]
            .split("=")[1];
        var param_mode = window.location.search
            .replace("?", "")
            .split("&")[0]
            .split("=")[0];

        var delimiter = "?";

        // в поле to нельзя выбрать дату раньше даты, выбранной во from

        var d = new Date();
        var current_date =
            d.getFullYear() +
            "-" +
            (d.getMonth() + 1 < 10
                ? "0" + (d.getMonth() + 1)
                : d.getMonth() + 1) +
            "-" +
            (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        var dtpkr_to = $(this).datepicker("getDate");
        var to_date =
            dtpkr_to.getFullYear() +
            "-" +
            (dtpkr_to.getMonth() + 1 < 10
                ? "0" + (dtpkr_to.getMonth() + 1)
                : dtpkr_to.getMonth() + 1) +
            "-" +
            (dtpkr_to.getDate() < 10
                ? "0" + dtpkr_to.getDate()
                : dtpkr_to.getDate());

        // if not in get(from_date)
        //var dtpkr_from = $("#datepicker-map-from").datepicker('getDate');
        //var from_date = dtpkr_from.getFullYear() + "-" + (dtpkr_from.getMonth() + 1) + "-" + ( dtpkr_from.getDate() < 10 ? ('0' + dtpkr_from.getDate()) : dtpkr_from.getDate() );

        // if in get(from_date)
        var from_date = $("#datepicker-map-from").val();

        //console.log(current_date);

        if (to_date < current_date) {
            to_date = current_date;
            $(this).val(from_date);
        }

        if (to_date <= from_date) {
            to_date = from_date;
            $(this).val(to_date);
        }

        //if (to_date > from_date) {
        //	from_date = to_date;
        //	$("#datepicker-map-from").val(from_date);
        //}

        if ($("#datepicker-map-from").val()) {
            if (
                $("#datepicker-map-from").datepicker("getDate") <=
                $(this).datepicker("getDate")
            ) {
                if (param_mode == "mode") {
                    window.location =
                        path +
                        delimiter +
                        "mode=" +
                        param +
                        "&date-from=" +
                        from_date +
                        "&date-to=" +
                        to_date;
                } else {
                    window.location =
                        path +
                        delimiter +
                        "date-from=" +
                        from_date +
                        "&date-to=" +
                        to_date;
                }
            } else {
                if (param_mode == "mode") {
                    window.location =
                        path +
                        delimiter +
                        "mode=" +
                        param +
                        "&date-from=" +
                        from_date +
                        "&date-to=" +
                        to_date;
                } else {
                    window.location =
                        path +
                        delimiter +
                        "date-from=" +
                        from_date +
                        "&date-to=" +
                        to_date;
                }
            }
        }
    });

    //param=maybego
    $(".buttons-gonnago-map .gonnago-event.maybe").click(function () {
        var path = window.location.pathname;
        var param_mode = window.location.search
            .replace("?", "")
            .split("&")[0]
            .split("=")[0];

        if (param_mode == "mode") {
            var param = window.location.search
                .replace("?", "")
                .split("&")[0]
                .split("=")[1];
        }

        var from_date = $("#datepicker-map-from").val();
        var to_date = $("#datepicker-map-to").val();

        if (from_date) {
            from_date_fist_param = "?date-from=" + from_date;
            from_date = "&date-from=" + from_date;
        }

        if (to_date) to_date = "&date-to=" + to_date;

        if (param == "all") {
            if ($(".gonnago-event.maybe").hasClass("selected")) {
                $(".gonnago-event.maybe").removeClass("selected");
                window.location = path + "?mode=gonnago" + from_date + to_date;
            } else {
                $(".gonnago-event.maybe").addClass("selected");
                window.location = path + "?mode=maybego" + from_date + to_date;
            }
        }

        if (param == undefined) {
            if ($(".gonnago-event.maybe").hasClass("selected")) {
                window.location = path + "?mode=gonnago" + from_date + to_date;
            } else {
                window.location = path + "?mode=maybego" + from_date + to_date;
            }
        }

        if (param == "maybego")
            window.location = path + from_date_fist_param + to_date;

        if (param == "gonnago")
            window.location = path + "?mode=all" + from_date + to_date;
    });

    //param=definitely
    $(".buttons-gonnago-map .gonnago-event.definitely").click(function () {
        var path = window.location.pathname;
        var param_mode = window.location.search
            .replace("?", "")
            .split("&")[0]
            .split("=")[0];

        if (param_mode == "mode") {
            var param = window.location.search
                .replace("?", "")
                .split("&")[0]
                .split("=")[1];
        }

        var from_date = $("#datepicker-map-from").val();
        var to_date = $("#datepicker-map-to").val();

        //console.log(from_date,to_date);

        if (from_date) {
            from_date_fist_param = "?date-from=" + from_date;
            from_date = "&date-from=" + from_date;
        }

        if (to_date) to_date = "&date-to=" + to_date;

        if (param == "all") {
            if ($(".gonnago-event.definitely").hasClass("selected")) {
                $(".gonnago-event.definitely").removeClass("selected");
                window.location = path + "?mode=maybego" + from_date + to_date;
            } else {
                $(".gonnago-event.definitely").addClass("selected");
                window.location = path + "?mode=gonnago" + from_date + to_date;
            }
        }

        if (param == undefined) {
            if ($(".gonnago-event.definitely").hasClass("selected")) {
                window.location = path + "?mode=maybego" + from_date + to_date;
            } else {
                window.location = path + "?mode=gonnago" + from_date + to_date;
            }
        }

        if (param == "maybego")
            window.location = path + "?mode=all" + from_date + to_date;

        if (param == "gonnago")
            window.location = path + from_date_fist_param + to_date;
    });

    $(".artist-result__genre").click(function() {
        $(this).addClass("pulse");
    });
});
